<template>
  <div
      class="evolve"
      v-tooltip="{ content: setEvolveTooltipContent, placement: 'top', offset: 10, html: true, classes: 'tooltip--400' }"
  >
    <div class="evolve__main">
      <div class="evolve__wrapper">
        <div class="evolve__pic" :class="{ 'evolve__pic--bordered': level === 5 }">
          <img :src="require(`@/assets/img/evolve/evolve-pic-${level}.jpg`)" alt="">
        </div>
        <img v-if="level !== 5" src="@/assets/img/evolve/evolve-info-icon.svg" alt="" class="evolve__info-icon">
        <img v-else src="@/assets/img/evolve/evolve-info-icon.svg" alt="" class="evolve__info-icon">
      </div>
      <div class="evolve__desc">
        <div class="evolve__title">{{ $t(`home.evolve.card_${level}_title`) }}</div>
        <div class="evolve__text">{{ $t(`home.evolve.card_${level}_text`) }}</div>
      </div>
    </div>
    <div class="evolve__progress">
      <span
          class="evolve__percent"
          :class="{ 'evolve__percent--completed': level === 5 }"
          :style="{ left: `${calcProgressPercent}%` }"
      >
        {{ calcProgressPercent }}%
      </span>
      <div
          class="evolve__line"
          :class="{ 'evolve__line--completed': level === 5 }"
          :style="{ width: `${calcProgressPercent}%` }"
      ></div>
      <div class="evolve__dots" :class="{ 'evolve__dots--completed': level === 5 }">
        <span class="evolve__dot" :class="{ 'evolve__dot--completed': level >= 1 }"></span>
        <span class="evolve__dot" :class="{ 'evolve__dot--completed': level >= 2 }"></span>
        <span class="evolve__dot" :class="{ 'evolve__dot--completed': level >= 3 }"></span>
        <span class="evolve__dot" :class="{ 'evolve__dot--completed': level >= 4 }"></span>
        <span class="evolve__dot" :class="{ 'evolve__dot--completed': level >= 5 }"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Evolve',
  props: {
    level: {
      type: Number,
      default: 1
    }
  },
  computed: {
    calcProgressPercent() {
      return (this.level * 25) - 25
    },
    setEvolveTooltipContent() {
      return `
        <div class="custom-tt-cnt">
          <div class="custom-tt-cnt__top">
            <div class="custom-tt-cnt__icon"></div>
            <div class="custom-tt-cnt__title">${this.$t(`home.evolve.tooltip_${this.level}_title`)}</div>
          </div>
          <div class="custom-tt-cnt__subtitle">${this.$t('home.evolve.tooltip_subtitle')}</div>
          <div class="custom-tt-cnt__text">
            <p>${this.$t(`home.evolve.tooltip_${this.level}_text_1`)}</p>
            <p>${this.$t(`home.evolve.tooltip_${this.level}_text_2`)}</p>
          </div>
        </div>
      `
    }
  }
}
</script>

<style lang="sass" scoped>
.evolve
  flex-shrink: 0
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 30px 10px
  width: 280px
  min-height: 176px
  padding: 20px
  border-radius: 10px
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1)
  background-color: #fff

  +max-w($laptop)
    width: 100%

.evolve__main
  display: flex
  align-items: flex-start
  gap: 20px

.evolve__wrapper
  position: relative

.evolve__pic
  width: 85px
  height: 85px
  border-radius: 50%
  overflow: hidden

  img
    width: 85px
    height: 85px
    object-fit: cover

.evolve__pic--bordered
  display: flex
  align-items: center
  justify-content: center
  width: 92px
  height: 92px
  background: linear-gradient(#ffe898, #ffa51F, #ffe793, #ffa51F)

  img
    border-radius: 50%
    border: 2px solid #fff
    box-sizing: content-box

.evolve__info-icon
  position: absolute
  right: 0
  bottom: 0
  border-radius: 50%
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15)

.evolve__title
  margin-bottom: 10px
  color: #181818
  font-weight: 600

.evolve__text
  color: $color-text-second
  font-size: 13px
  line-height: 16px

.evolve__progress
  position: relative
  width: 92%
  height: 4px
  margin: 0 auto
  border-radius: 2px
  background-color: #ebebeb

.evolve__line
  position: absolute
  top: 0
  left: 0
  height: 100%
  background-color: $color-theme

.evolve__line--completed
  background-color: #fec006

.evolve__dots--completed
  .evolve__dot
    background-color: #fec006

.evolve__dot
  position: absolute
  top: -1px
  width: 6px
  height: 6px
  border-radius: 50%
  background-color: #dbdbdb

  &:nth-child(1)
    left: -3px

  &:nth-child(2)
    left: calc(25% - 3px)

  &:nth-child(3)
    left: calc(50% - 3px)

  &:nth-child(4)
    left: calc(75% - 3px)

  &:nth-child(5)
    left: calc(100% - 3px)

.evolve__dot--completed
  background-color: $color-theme

.evolve__percent
  position: absolute
  top: -24px
  color: $color-theme
  font-size: 13px
  font-weight: 600
  transform: translateX(-50%)

.evolve__percent--completed
  color: #fec006

</style>
