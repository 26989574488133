<template>
  <div class="lang-switcher" @focusout="onFocusOut">
    <button @click="onClickTrigger" class="lang-switcher__trigger">
      <span class="lang-switcher__icon">
        <img :src="require(`@/assets/img/${getCurrentLanguage.icon}.svg`)" alt="lang-icon">
      </span>
      <span class="lang-switcher__name">{{ getCurrentLanguage.label }}</span>
    </button>
    <transition name="tooltip">
      <div v-show="show_tooltip" class="lang-switcher__tooltip">
        <div class="lang-switcher__arrow"></div>
        <button
          @click="onClickLang(lang.id)"
          v-for="lang in languages"
          :key="lang.id"
          class="lang-switcher__item"
          :class="{'lang-switcher__item--active': lang.id === current_language}"
        >
          <img :src="require(`@/assets/img/${lang.icon}.svg`)" alt="lang-icon">
          <span>{{ lang.label }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { postClientLang } from '@/http'

export default {
  name: 'AppLangSwitcher',
  data() {
    return {
      current_language: 'us',
      show_tooltip: false,
      languages: [
        { id: 'us', label: 'English', icon: 'us-flag' },
        { id: 'ru', label: 'Русский', icon: 'ru-flag' },
        // { id: 'de', label: 'Deutsch', icon: 'de-flag' },
      ]
    }
  },
  created() {
    const user_language = localStorage.getItem('content_language')
    if (user_language) this.current_language = user_language
  },
  methods: {
    onClickTrigger() {
      this.show_tooltip = !this.show_tooltip
    },
    onClickLang(lang) {
      this.current_language = lang
      this.show_tooltip = false
      localStorage.setItem('content_language', lang)
      this.$i18n.locale = lang
      if (this.$route.params.hash) {
        postClientLang(this.$route.params.hash, { content_language: lang }).then(() => { location.reload() })
      } else { location.reload() }
    },
    onFocusOut() {
      this.show_tooltip = false
    },
  },
  computed: {
    getCurrentLanguage() {
      return this.languages.find(lang => lang.id === this.current_language)
    },
  }
}
</script>

<style scoped lang="sass">
.lang-switcher
  position: relative

.lang-switcher__trigger
  display: flex
  align-items: center
  gap: 5px

.lang-switcher__icon
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 22px
  height: 22px
  border: 1px solid #c2c2c2
  border-radius: 50%
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)
  line-height: 0

  img
    width: 14px
    height: 14px

.lang-switcher__name
  min-width: 47px
  color: #8a8a8a
  font-size: 12px

.lang-switcher__tooltip
  position: absolute
  right: -10px
  margin-top: 10px
  padding: 8px 20px
  border-radius: 10px
  background-color: #fff
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15)
  z-index: 1

//.lang-switcher__arrow
//  position: absolute
//  top: -10px
//  right: 16px
//  width: 33px
//  height: 11px
//  background-image: url("~@/assets/img/project/tooltip-arrow.svg")
//  background-repeat: no-repeat
//  background-position: right top

.lang-switcher__item
  display: flex
  align-items: center
  gap: 10px
  width: 96px
  padding: 10px 0
  border-bottom: 1px solid rgba(#000, 0.05)

  &:active
    span
      color: $color-theme

  @media (hover: hover)
    span
      &:hover,
      &:focus-visible
        color: $color-theme

  &:last-child
    border-bottom: none

  span
    color: #202020
    font-size: 12px
    font-weight: 500
    transition: color 0.3s

.lang-switcher__item--active
  span
    color: $color-theme

.tooltip-enter-active, .tooltip-leave-active
  transition: opacity 0.3s

.tooltip-enter, .tooltip-leave-to
  opacity: 0
</style>
