<template>
  <div class="table-limit">
    <span class="table-limit__text">Records per page:</span>
    <v-select
      @input="onUpdate"
      v-model="limit_selected"
      :options="limit_options"
      :filterable="false"
      :clearable="false"
      :searchable="false"
      label="name"
      placeholder="Select option"
      class="select select--small"
    >
      <template #open-indicator>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
          <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
        </svg>
      </template>
      <template #option="option">
        <div class="select__item d-center">{{ option.name }}</div>
      </template>
      <template #selected-option="option">
        <div class="selected d-center">{{ option.name }}</div>
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  name: 'AppTableLimit',
  props: {
    limit: {
      type: Number,
      required: false,
      default: 20
    }
  },
  data() {
    return {
      limit_selected: { name: this.limit },
      limit_options: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    }
  },
  methods: {
    onUpdate(data) {
      this.limit_selected.name = data.name
      this.$emit('update-limiter', data.name)
    },
  }
}
</script>

<style scoped lang="sass">
.table-limit
  display: flex
  align-items: center
  gap: 10px

.table-limit__text
  color: $color-text-second
  font-size: 13px
</style>
