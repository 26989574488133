<template>
  <div class="personal">
    <div class="personal__maint">{{ $t('home.personal.title') }}</div>
    <div class="personal__subt">{{ $t('home.personal.subt') }}</div>
    <div v-if="days_before_arrival && days_before_arrival < 14" class="personal__note">
      <img src="@/assets/img/warning-icon.svg" alt="">
      <p>{{ $t('home.personal.warning') }}</p>
    </div>
    <div class="personal__text">{{ $t('home.personal.text') }}</div>
    <div class="personal__block">
      <button
        @click="show_personal = !show_personal"
        class="personal__header"
        :class="{ 'personal__header--show': show_personal }"
      >
        <svg
          class="personal__icon"
          :class="{ 'personal__icon--complited': personal.personal_data_percentage === 100 }"
          width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#77B017"/>
          <path d="M7.063 10.884a.415.415 0 0 1-.288.116.415.415 0 0 1-.29-.116L4.18 8.656a.577.577 0 0 1 0-.836l.288-.28a.628.628 0 0 1 .867 0l1.44 1.391 3.89-3.758a.628.628 0 0 1 .867 0l.288.28c.24.23.24.605 0 .836l-4.757 4.595Z" fill="#fff"/>
        </svg>
        <span class="personal__title">
          {{ $t('home.personal.row_title_1') }}
        </span>
      </button>
      <transition name="slide">
        <div v-if="show_personal" class="personal__body">
          <form class="personal__form">
            <div class="personal__part">
              <div class="personal__name">
                <img src="@/assets/img/personal-details.svg" alt="personal">
                <span>{{ $t('home.personal.row_title_1') }}</span>
              </div>
              <div class="personal__row personal__row--mix-1">
                <app-form-group :label="$t('home.personal.first_name')" label-for="first_name">
                  <template #additional>
                    <app-button
                        v-tooltip="{content: $t('contact_your_m'), placement: 'top-start', offset: 10}"
                        theme="tooltip">
                    </app-button>
                  </template>
                  <app-input
                      v-model="personal.first_name"
                      id="first_name"
                      :placeholder="$t('home.personal.first_name_placeholder')"
                      @input="onChangeRUToUS($event, 'first_name')"
                      name="first_name"
                      autocomplete="off"
                      readonly
                  />
                </app-form-group>
                <app-form-group :label="$t('home.personal.last_name')" label-for="last_name">
                  <template #additional>
                    <app-button
                        v-tooltip="{content: $t('contact_your_m'), placement: 'top-start', offset: 10}"
                        theme="tooltip">
                    </app-button>
                  </template>
                  <app-input
                      v-model="personal.last_name"
                      id="last_name"
                      :placeholder="$t('home.personal.last_name_placeholder')"
                      @input="onChangeRUToUS($event, 'last_name')"
                      name="last_name"
                      autocomplete="off"
                      readonly
                  />
                </app-form-group>
                <app-form-group :label="$t('home.personal.dob')">
                  <date-picker
                      v-model="personal.dob"
                      format="DD MMM YYYY"
                      value-type="YYYY-MM-DD"
                      :placeholder="$t('home.personal.dob_placeholder')"
                      class="datepicker"
                      popup-class="datepicker__popup"
                      title-format="DD MMM YYYY"
                      :lang="getCurrentLang"
                      :class="{'datepicker--error': $v.personal.dob.$error}"
                      name="dob"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.dob.$dirty && !$v.personal.dob.minValue">{{ $t('validations.dob_min') }}</div>
                    <div v-if="$v.personal.dob.$dirty && !$v.personal.dob.maxValue">{{ $t('validations.dob_max') }}</div>
                  </template>
                </app-form-group>
                <app-form-group :label="$t('home.personal.gender')" class="form-group--center">
                  <app-cells :indent="false">
                    <app-radio
                        v-for="item in genders_options"
                        :key="item.code"
                        v-model.number="personal.gender"
                        :value="item.code"
                        name="gender"
                        :checked="personal.gender"
                    >
                      <template #label>{{ item.name }}</template>
                    </app-radio>
                  </app-cells>
                </app-form-group>
              </div>
              <div class="personal__row personal__row--mix-2">
                <app-form-group :label="$t('home.personal.citizenship')">
                  <v-select
                      v-model="personal.citizenship"
                      :reduce="item => item.code"
                      :options="countries_options"
                      :filterable="true"
                      :clearable="false"
                      :searchable="true"
                      label="name"
                      :placeholder="$t('home.personal.citizenship')"
                      class="select"
                  >
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #option="option">
                      <div class="select__item d-center">{{ option.name }}</div>
                    </template>
                    <template #selected-option="option">
                      <div class="selected d-center">{{ option.name }}</div>
                    </template>
                  </v-select>
                </app-form-group>
<!--                <app-form-group :label="$t('home.personal.residence')">-->
<!--                  <v-select-->
<!--                      v-model="personal.residence"-->
<!--                      :reduce="item => item.code"-->
<!--                      :options="countries_options"-->
<!--                      :filterable="true"-->
<!--                      :clearable="false"-->
<!--                      :searchable="true"-->
<!--                      label="name"-->
<!--                      :placeholder="$t('home.personal.residence_placeholder')"-->
<!--                      class="select"-->
<!--                  >-->
<!--                    <template #open-indicator>-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">-->
<!--                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>-->
<!--                      </svg>-->
<!--                    </template>-->
<!--                    <template #open-indicator>-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">-->
<!--                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>-->
<!--                      </svg>-->
<!--                    </template>-->
<!--                    <template #option="option">-->
<!--                      <div class="select__item d-center">{{ option.name }}</div>-->
<!--                    </template>-->
<!--                    <template #selected-option="option">-->
<!--                      <div class="selected d-center">{{ option.name }}</div>-->
<!--                    </template>-->
<!--                  </v-select>-->
<!--                </app-form-group>-->
                <app-form-group :label="$t('home.personal.lang')">
                  <v-select
                      v-model="personal.speaking_language"
                      :reduce="item => item.code"
                      :options="languages_options"
                      :filterable="true"
                      :clearable="false"
                      :searchable="true"
                      label="name"
                      :placeholder="$t('home.personal.lang_placeholder')"
                      class="select"
                  >
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #option="option">
                      <div class="select__item d-center">{{ option.name }}</div>
                    </template>
                    <template #selected-option="option">
                      <div class="selected d-center">{{ option.name }}</div>
                    </template>
                  </v-select>
                </app-form-group>
                <app-form-group :label="$t('home.personal.pass_number')" label-for="passport">
                  <app-input
                      v-model="personal.passport"
                      id="passport"
                      placeholder="-- ------"
                      name="passport_num"
                      autocomplete="off"
                  />
                </app-form-group>
                <app-form-group :label="$t('home.personal.pass_exp')">
                  <date-picker
                      v-model="personal.passport_expiration_date"
                      format="DD MMM YYYY"
                      value-type="YYYY-MM-DD"
                      :placeholder="$t('home.personal.pass_exp_placeholder')"
                      class="datepicker"
                      popup-class="datepicker__popup"
                      title-format="DD MMM YYYY"
                      :lang="getCurrentLang"
                      :class="{'datepicker--error': $v.personal.passport_expiration_date.$error}"
                      name="pass_exp"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.passport_expiration_date.$dirty && !$v.personal.passport_expiration_date.minValue">
                      {{ $t('validations.date_min_today') }}
                    </div>
                  </template>
                </app-form-group>
              </div>
              <template v-if="requirements.passport_required || requirements.residence_permit_required">
                <app-info-text-panel>
                  {{ $t('home.personal.pass_reside_permits_text') }}
                </app-info-text-panel>
                <div class="personal__row personal__row--mix-2">
                  <app-form-group v-if="requirements.passport_required" :label="$t('home.personal.pass_permit_label')">
                    <app-uploader v-model="personal.passport_copy" :extensions="['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']" />
                  </app-form-group>
                  <app-form-group v-if="requirements.residence_permit_required" :label="$t('home.personal.residence_permit_label')">
                    <app-uploader v-model="personal.residence_permit_copy" :extensions="['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']" />
                  </app-form-group>
                </div>
              </template>
              <app-form-group v-if="climbing_insurance_required || medical_clearance_required">
                <app-cropper-web-cam
                  v-model="personal.crop"
                  :aspectRatio="3/4"
                  ref="cropper"
                />
              </app-form-group>
            </div>
            <div class="personal__part">
              <div class="personal__name">
                <img src="@/assets/img/contact-details.svg" alt="contacts">
                <span>{{ $t('home.personal.row_title_2') }}</span>
              </div>
              <div class="personal__row personal__row--type-200">
                <app-form-group :label="$t('home.personal.email')" label-for="email">
                  <app-input
                      v-model="personal.email"
                      @change.native="$v.personal.email.$touch"
                      :error="$v.personal.email.$error"
                      id="email"
                      type="email"
                      placeholder="mail@example.com"
                      name="email"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.email.$dirty && !$v.personal.email.email">{{ $t('validations.email') }}</div>
                  </template>
                </app-form-group>
                <app-form-group :label="$t('home.personal.phone')">
                  <app-phone
                      @change.native="$v.personal.phone.$touch()"
                      v-model="personal.phone"
                      :error="$v.personal.phone.$dirty && personal.phone === 0"
                      placeholder="+7 499 113 28 00"
                      name="phone"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.phone.$dirty && personal.phone === 0">
                      {{ $t('validations.phone') }}
                    </div>
                  </template>
                </app-form-group>
                <app-form-group :label="$t('home.personal.messangers')" class="form-group--center">
                  <app-cells :indent="false">
                    <app-checkbox v-model="personal.phone_telegram" label="Telegram" :readonly="!personal.phone" />
                    <app-checkbox v-model="personal.phone_whatsapp" label="Whatsapp" :readonly="!personal.phone" />
                  </app-cells>
                </app-form-group>
              </div>
              <div class="personal__name">
                <img src="@/assets/img/emer-contacts.svg" alt="personal">
                <span>{{ $t('home.personal.row_title_emer') }}</span>
              </div>
              <div class="personal__row personal__row--type-200">
                <app-form-group :label="$t('home.personal.emergency_name')" label-for="emergency_full_name">
                  <app-input
                      v-model="personal.emergency_contact_fullname"
                      id="emergency_full_name"
                      placeholder="John Doe"
                  />
                </app-form-group>
                <app-form-group :label="$t('home.personal.emergency_email')" label-for="emergency_email">
                  <app-input
                      v-model="personal.emergency_contact_email"
                      @change.native="$v.personal.emergency_contact_email.$touch"
                      :error="$v.personal.emergency_contact_email.$error"
                      id="emergency_email"
                      type="email"
                      placeholder="mail@example.com"
                      name="emergency_email"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.emergency_contact_email.$dirty && !$v.personal.emergency_contact_email.email">
                      {{ $t('validations.email') }}
                    </div>
                  </template>
                </app-form-group>
                <app-form-group :label="$t('home.personal.emergency_phone')">
                  <app-phone
                      @change.native="$v.personal.emergency_contact_phone.$touch()"
                      v-model="personal.emergency_contact_phone"
                      placeholder="+7 499 113 28 00"
                      :error="$v.personal.emergency_contact_phone.$dirty && personal.emergency_contact_phone === 0"
                      name="emergency_phone"
                      autocomplete="off"
                  />
                  <template #error>
                    <div v-if="$v.personal.emergency_contact_phone.$dirty && personal.emergency_contact_phone === 0">
                      {{ $t('validations.phone') }}
                    </div>
                  </template>
                </app-form-group>
                <app-form-group :label="$t('home.personal.emergency_messangers')" class="form-group--center">
                  <app-cells :indent="false">
                    <app-checkbox
                        v-model="personal.emergency_contact_phone_telegram"
                        label="Telegram"
                        :readonly="!personal.emergency_contact_phone"
                    />
                    <app-checkbox
                        v-model="personal.emergency_contact_phone_whatsapp"
                        label="Whatsapp"
                        :readonly="!personal.emergency_contact_phone"
                    />
                  </app-cells>
                </app-form-group>
              </div>
            </div>
            <div class="personal__part">
              <div class="personal__name">
                <img src="@/assets/img/food-preference.svg" alt="food">
                <span>{{ $t('home.personal.row_title_3') }}</span>
              </div>
              <div class="personal__row personal__row--mix-diet">
                <app-form-group :label="$t('home.personal.diet')">
                  <v-select
                      v-model="personal.food_preference"
                      :options="food_options"
                      :filterable="false"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      :placeholder="$t('home.personal.diet')"
                      class="select"
                  >
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #open-indicator>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                      </svg>
                    </template>
                    <template #option="option">
                      <div class="select__item d-center">{{ option.name }}</div>
                    </template>
                    <template #selected-option="option">
                      <div class="selected d-center">{{ option.name }}</div>
                    </template>
                  </v-select>
                </app-form-group>
                <div class="personal__diet-switchers">
                  <app-form-group :label="$t('home.personal.allergies_label')" class="form-group--center-switch">
                    <app-switcher v-model="allergies_switcher" />
                  </app-form-group>
                  <app-form-group :label="$t('home.personal.lactose')" class="form-group--center-switch">
                    <app-switcher v-model="personal.food_lactose_free" />
                  </app-form-group>
                  <app-form-group :label="$t('home.personal.gluten')" class="form-group--center-switch">
                    <app-switcher v-model="personal.food_gluten_free" />
                  </app-form-group>
                </div>
                <div v-if="personal.food_preference && personal.food_preference.is_meat" class="personal__diet-switchers">
                  <app-form-group :label="$t('home.personal.food_halal')" class="form-group--center-switch">
                    <app-switcher v-model="personal.food_halal" />
                  </app-form-group>
                  <app-form-group :label="$t('home.personal.food_no_red_meat')" class="form-group--center-switch">
                    <app-switcher v-model="personal.food_no_red_meat" />
                  </app-form-group>
                  <app-form-group :label="$t('home.personal.food_no_chicken')" class="form-group--center-switch">
                    <app-switcher v-model="personal.food_no_chicken" />
                  </app-form-group>
                </div>
              </div>
              <div v-if="allergies_switcher" class="personal__row personal__row--mix-5">
                <app-form-group v-if="allergies_switcher" :label="$t('home.personal.allergies')" label-for="food">
                  <app-input
                    v-model="personal.food_allergy"
                    id="food"
                    :placeholder="$t('home.personal.allergies-placeholder')"
                  />
                </app-form-group>
              </div>
            </div>
            <app-cells :indent="false" position="end">
              <app-button @click.native="onCheckForm(personal, 'personal')" ref="submit_personal">
                {{ $t('home.personal.row_button_save') }}
              </app-button>
            </app-cells>
          </form>
        </div>
      </transition>
    </div>
    <div v-if="climbing_insurance_required" class="personal__block">
      <button @click="show_climbing = !show_climbing" class="personal__header" :class="{ 'personal__header--show': show_climbing }">
        <svg
            class="personal__icon"
            :class="{ 'personal__icon--complited': isClimbingFilled === Object.keys(climbing_insurance).length }"
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#77B017"/>
          <path d="M7.063 10.884a.415.415 0 0 1-.288.116.415.415 0 0 1-.29-.116L4.18 8.656a.577.577 0 0 1 0-.836l.288-.28a.628.628 0 0 1 .867 0l1.44 1.391 3.89-3.758a.628.628 0 0 1 .867 0l.288.28c.24.23.24.605 0 .836l-4.757 4.595Z" fill="#fff"/>
        </svg>
        <span class="personal__title">
          {{ $t('home.personal.row_title_5') }}
        </span>
      </button>
      <transition name="slide-small">
        <div v-if="show_climbing" class="personal__body">
          <div class="yellow-frame  yellow-frame--m-t">
            <div class="yellow-frame__text">
              <div v-if="branding === 'false'" class="auth__title">{{ $t('home.personal.ins_y_text') }}</div>
              <div v-if="branding === 'true'" class="auth__title">{{ $t('home.personal.ins_y_text_branding') }}</div>
            </div>
          </div>
          <form class="personal__form">
            <div class="personal__row personal__row--mix-4">
              <app-form-group :label="$t('home.personal.ins_name')" label-for="climbing_insurance_company" required>
                <app-input
                    v-model="climbing_insurance.climbing_insurance_company"
                    id="climbing_insurance_company"
                    :placeholder="$t('home.personal.ins_name')"
                    :error="$v.climbing_insurance.climbing_insurance_company.$error"
                    name="climbing_insurance_company"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.climbing_insurance.climbing_insurance_company.$dirty && !$v.climbing_insurance.climbing_insurance_company.required">
                    {{ $t('validations.required') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.ins_number')" label-for="climbing_insurance_number" required>
                <app-input
                    v-model="climbing_insurance.climbing_insurance_number"
                    id="climbing_insurance_number"
                    placeholder="--- ------"
                    :error="$v.climbing_insurance.climbing_insurance_number.$error"
                    name="climbing_insurance_number"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.climbing_insurance.climbing_insurance_number.$dirty && !$v.climbing_insurance.climbing_insurance_number.required">
                    {{ $t('validations.required') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.valid_from')" required>
                <date-picker
                    v-model="climbing_insurance.climbing_insurance_coverage_start"
                    format="DD MMM YYYY"
                    value-type="YYYY-MM-DD"
                    :placeholder="$t('home.personal.valid_from_placeholder')"
                    class="datepicker"
                    popup-class="datepicker__popup"
                    title-format="DD MMM YYYY"
                    :lang="getCurrentLang"
                    :class="{ 'datepicker--error': $v.climbing_insurance.climbing_insurance_coverage_start.$error }"
                    name="climbing_insurance_coverage_start"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.climbing_insurance.climbing_insurance_coverage_start.$dirty && !$v.climbing_insurance.climbing_insurance_coverage_start.required">
                    {{ $t('validations.required') }}
                  </div>
                  <div v-if="$v.climbing_insurance.climbing_insurance_coverage_start.$dirty && !$v.climbing_insurance.climbing_insurance_coverage_start.maxValue">
                    {{ $t('validations.start_max_end') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.valid_until')" required>
                <date-picker
                    v-model="climbing_insurance.climbing_insurance_coverage_end"
                    format="DD MMM YYYY"
                    value-type="YYYY-MM-DD"
                    :placeholder="$t('home.personal.valid_until_placeholder')"
                    class="datepicker"
                    popup-class="datepicker__popup"
                    title-format="DD MMM YYYY"
                    :lang="getCurrentLang"
                    :class="{ 'datepicker--error': $v.climbing_insurance.climbing_insurance_coverage_end.$error }"
                    name="climbing_insurance_coverage_end"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.climbing_insurance.climbing_insurance_coverage_end.$dirty && !$v.climbing_insurance.climbing_insurance_coverage_end.required">
                    {{ $t('validations.required') }}
                  </div>
                  <div v-if="$v.climbing_insurance.climbing_insurance_coverage_end.$dirty && !$v.climbing_insurance.climbing_insurance_coverage_end.minValue">
                    {{ $t('validations.date_min_today') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.emer_phone')">
                <app-phone
                    v-model="climbing_insurance.climbing_insurance_emergency_phone"
                    placeholder="+7 499 113 28 00"
                    :error="$v.climbing_insurance.climbing_insurance_emergency_phone.$dirty && (climbing_insurance.climbing_insurance_emergency_phone === 0)"
                    name="climbing_insurance_emergency_phone"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.climbing_insurance.climbing_insurance_emergency_phone.$dirty && (climbing_insurance.climbing_insurance_emergency_phone === 0)">
                    {{ $t('validations.phone') }}
                  </div>
                </template>
              </app-form-group>
            </div>
            <app-cells :indent="false" position="end">
              <app-button @click.native="onCheckForm(climbing_insurance, 'climbing')" ref="submit_climbing">
                {{ $t('home.personal.row_button_save') }}
              </app-button>
            </app-cells>
          </form>
        </div>
      </transition>
    </div>
    <div v-if="safari_insurance_required" class="personal__block">
      <button @click="show_safari = !show_safari" class="personal__header" :class="{ 'personal__header--show': show_safari }">
        <svg
            class="personal__icon"
            :class="{ 'personal__icon--complited': isSafariFilled === Object.keys(safari_insurance).length }"
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#77B017"/>
          <path d="M7.063 10.884a.415.415 0 0 1-.288.116.415.415 0 0 1-.29-.116L4.18 8.656a.577.577 0 0 1 0-.836l.288-.28a.628.628 0 0 1 .867 0l1.44 1.391 3.89-3.758a.628.628 0 0 1 .867 0l.288.28c.24.23.24.605 0 .836l-4.757 4.595Z" fill="#fff"/>
        </svg>
        <span class="personal__title">{{ $t('home.personal.row_title_6') }}</span>
      </button>
      <transition name="slide-small">
        <div v-if="show_safari" class="personal__body">
          <form class="personal__form">
            <div class="personal__row personal__row--mix-4">
              <app-form-group :label="$t('home.personal.safari_ins_name')" label-for="safari_insurance_company" required>
                <app-input
                    v-model="safari_insurance.safari_insurance_company"
                    id="safari_insurance_company"
                    :placeholder="$t('home.personal.safari_ins_name')"
                    :error="$v.safari_insurance.safari_insurance_company.$error"
                    name="safari_insurance_company"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.safari_insurance.safari_insurance_company.$dirty && !$v.safari_insurance.safari_insurance_company.required">
                    {{ $t('validations.required') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.safari_ins_number')" label-for="safari_insurance_number" required>
                <app-input
                    v-model="safari_insurance.safari_insurance_number"
                    id="safari_insurance_number"
                    placeholder="--- ------"
                    :error="$v.safari_insurance.safari_insurance_number.$error"
                    name="safari_insurance_number"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.safari_insurance.safari_insurance_number.$dirty && !$v.safari_insurance.safari_insurance_number.required">
                    {{ $t('validations.required') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.start_cover')" required>
                <date-picker
                    v-model="safari_insurance.safari_insurance_coverage_start"
                    format="DD MMM YYYY"
                    value-type="YYYY-MM-DD"
                    :placeholder="$t('home.personal.start_cover_placeholder')"
                    class="datepicker"
                    popup-class="datepicker__popup"
                    title-format="DD MMM YYYY"
                    :lang="getCurrentLang"
                    :class="{ 'datepicker--error': $v.safari_insurance.safari_insurance_coverage_start.$error }"
                    name="safari_insurance_coverage_start"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.safari_insurance.safari_insurance_coverage_start.$dirty && !$v.safari_insurance.safari_insurance_coverage_start.required">
                    {{ $t('validations.required') }}
                  </div>
                  <div v-if="$v.safari_insurance.safari_insurance_coverage_start.$dirty && !$v.safari_insurance.safari_insurance_coverage_start.maxValue">
                    {{ $t('validations.start_max_end') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.end_cover')" required>
                <date-picker
                    v-model="safari_insurance.safari_insurance_coverage_end"
                    format="DD MMM YYYY"
                    value-type="YYYY-MM-DD"
                    :placeholder="$t('home.personal.end_cover_placeholder')"
                    class="datepicker"
                    popup-class="datepicker__popup"
                    title-format="DD MMM YYYY"
                    :lang="getCurrentLang"
                    :class="{ 'datepicker--error': $v.safari_insurance.safari_insurance_coverage_end.$error }"
                    name="safari_insurance_coverage_end"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.safari_insurance.safari_insurance_coverage_end.$dirty && !$v.safari_insurance.safari_insurance_coverage_end.required">
                    {{ $t('validations.required') }}
                  </div>
                  <div v-if="$v.safari_insurance.safari_insurance_coverage_end.$dirty && !$v.safari_insurance.safari_insurance_coverage_end.minValue">
                    {{ $t('validations.date_min_today') }}
                  </div>
                </template>
              </app-form-group>
              <app-form-group :label="$t('home.personal.safari_emer_phone')">
                <app-phone
                    v-model="safari_insurance.safari_insurance_emergency_phone"
                    placeholder="+7 499 113 28 00"
                    :error="$v.safari_insurance.safari_insurance_emergency_phone.$dirty && (safari_insurance.safari_insurance_emergency_phone === 0)"
                    name="safari_insurance_emergency_phone"
                    autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.safari_insurance.safari_insurance_emergency_phone.$dirty && (safari_insurance.safari_insurance_emergency_phone === 0)">
                    {{ $t('validations.phone') }}
                  </div>
                </template>
              </app-form-group>
            </div>
            <app-cells :indent="false" position="end">
              <app-button @click.native="onCheckForm(safari_insurance, 'safari')" ref="submit_safari">
                {{ $t('home.personal.row_button_save') }}
              </app-button>
            </app-cells>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { getCountries, getGenders, getLangueage, getFoodPreferences, patchPersonalArea } from '@/http'
import AppInfoTextPanel from '@/components/AppInfoTextPanel'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/en'

export default {
  name: 'Personal',
  components: { AppInfoTextPanel },
  props: {
    personal: {
      type: Object
    },
    climbing_insurance: {
      type: Object
    },
    safari_insurance: {
      type: Object
    },
    climbing_insurance_required: {
      type: Boolean
    },
    safari_insurance_required: {
      type: Boolean
    },
    medical_clearance_required: {
      type: Boolean
    },
    days_before_arrival: {
      type: Number
    },
    requirements: {
      type: Object
    }
  },
  validations: {
    personal: {
      email: { email },
      emergency_contact_email: { email },
      phone: {},
      emergency_contact_phone: {},
      dob: {
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date('1930-01-01').setHours(0, 0, 0, 0)) : true
        },
        maxValue(val) {
          return new Date(val) < new Date()
        },
      },
      passport_expiration_date: {
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        },
      },
    },
    climbing_insurance: {
      climbing_insurance_company: { required },
      climbing_insurance_number: { required },
      climbing_insurance_coverage_start: {
        required,
        maxValue(val) {
          return new Date(val) < new Date(this.climbing_insurance.climbing_insurance_coverage_end)
        },
      },
      climbing_insurance_coverage_end: {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        },
      },
      climbing_insurance_emergency_phone: {}
    },
    safari_insurance: {
      safari_insurance_company: { required },
      safari_insurance_number: { required },
      safari_insurance_coverage_start: {
        required,
        maxValue(val) {
          return new Date(val) < new Date(this.safari_insurance.safari_insurance_coverage_end)
        },
      },
      safari_insurance_coverage_end: {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        },
      },
      safari_insurance_emergency_phone: {}
    }
  },
  data() {
    return {
      show_personal: true,
      show_climbing: false,
      show_safari: false,
      genders_options: [],
      countries_options: [],
      languages_options: [],
      food_options: [],
      allergies_switcher: false,
      // preferences_switcher: false
    }
  },
  created() {
    getGenders().then(res => { this.genders_options = res.data })
    getCountries().then(res => { this.countries_options = res.data })
    getLangueage().then(res => { this.languages_options = res.data })
    getFoodPreferences().then(res => { this.food_options = res.data })
    if (this.personal.food_allergy) this.allergies_switcher = true
    // if (this.personal.food_preferences) this.preferences_switcher = true
  },
  watch: {
    allergies_switcher(val) {
      if (!val) {
        this.personal.food_allergy = null
      }
    },
    // preferences_switcher(val) {
    //   if (!val) {
    //     this.personal.food_preferences = null
    //   }
    // },
  },
  computed: {
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
    getCurrentLang() {
      return localStorage.getItem('content_language') === 'ru' ? 'ru' : 'en'
    },
    isClimbingFilled() {
      let count = 0
      for (const prop in this.climbing_insurance) {
        if (this.climbing_insurance[prop]) count += 1
      }
      return count
    },
    isSafariFilled() {
      let count = 0
      for (const prop in this.safari_insurance) {
        if (this.safari_insurance[prop]) count += 1
      }
      return count
    },
    isPersonalFilled() {
      let count = 0
      for (const prop in this.personal) {
        if (this.personal[prop] && !['phone_telegram', 'phone_whatsapp', 'emergency_contact_phone_telegram', 'emergency_contact_phone_whatsapp', 'food_gluten_free', 'food_lactose_free'].includes(prop)) count += 1
      }
      return count
    },
  },
  methods: {
    onChangeRUToUS(value, prop) {
      let answer = ''
      const converter = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'e',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'y',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'h',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'щ': 'sch',
        'ь': '',
        'ы': 'y',
        'ъ': '',
        'э': 'e',
        'ю': 'yu',
        'я': 'ya',
        'А': 'A',
        'Б': 'B',
        'В': 'V',
        'Г': 'G',
        'Д': 'D',
        'Е': 'E',
        'Ё': 'E',
        'Ж': 'Zh',
        'З': 'Z',
        'И': 'I',
        'Й': 'Y',
        'К': 'K',
        'Л': 'L',
        'М': 'M',
        'Н': 'N',
        'О': 'O',
        'П': 'P',
        'Р': 'R',
        'С': 'S',
        'Т': 'T',
        'У': 'U',
        'Ф': 'F',
        'Х': 'H',
        'Ц': 'C',
        'Ч': 'Ch',
        'Ш': 'Sh',
        'Щ': 'Sch',
        'Ь': '',
        'Ы': 'Y',
        'Ъ': '',
        'Э': 'E',
        'Ю': 'Yu',
        'Я': 'Ya'
      }

      for (let i = 0; i < value.length; ++i) {
        if (converter[value[i]] === undefined) {
          answer += value[i]
        } else {
          answer += converter[value[i]]
        }
      }
      this.personal[prop] = answer
    },
    onCheckForm(data, type) {
      if (type === 'personal') {
        this.$v.personal.$touch()
        if (this.$v.personal.$invalid) {
          this.$notify({
            type: 'error',
            title: this.$t('notify.check'),
          })
        } else {
          if (this.personal.crop.coords && !this.personal.crop.photo) {
            this.$refs.submit_personal.preload = true
            this.$refs.cropper.sendCrop()
              .then(() => { this.sendForm(data, type) })
              .catch(() => { this.$refs.submit_personal.preload = false })
          } else {
            this.sendForm(data, type)
          }
        }
      } else if (type === 'climbing') {
        this.$v.climbing_insurance.$touch()
        if (this.$v.climbing_insurance.$invalid) {
          this.$notify({
            type: 'error',
            title: this.$t('notify.check'),
          })
        } else {
          this.sendForm(data, type)
        }
      } else {
        this.$v.safari_insurance.$touch()
        if (this.$v.safari_insurance.$invalid) {
          this.$notify({
            type: 'error',
            title: this.$t('notify.check'),
          })
        } else {
          this.sendForm(data, type)
        }
      }
    },
    sendForm(data, type) {
      if (type === 'personal') {
        this.$refs.submit_personal.preload = true
        patchPersonalArea(this.$route.params.hash, this.normalizeForm(data))
          .finally(() => { if (this.$refs.submit_personal) this.$refs.submit_personal.preload = false })
          .then(() => {
            this.$notify({
              type: 'success',
              title: this.$t('notify.data_save'),
            })
            this.$emit('getHomeData')
          })
      } else if (type === 'climbing') {
        this.$refs.submit_climbing.preload = true
        patchPersonalArea(this.$route.params.hash, data)
          .finally(() => { if (this.$refs.submit_climbing) this.$refs.submit_climbing.preload = false })
          .then(() => {
            this.$notify({
              type: 'success',
              title: this.$t('notify.data_save'),
            })
            this.$emit('getHomeData')
          })
      } else {
        this.$refs.submit_safari.preload = true
        patchPersonalArea(this.$route.params.hash, data)
          .finally(() => { if (this.$refs.submit_safari) this.$refs.submit_safari.preload = false })
          .then(() => {
            this.$notify({
              type: 'success',
              title: this.$t('notify.data_save'),
            })
            this.$emit('getHomeData')
          })
      }
    },
    normalizeForm(data) {
      const normalizedForm = { ...data }

      if (normalizedForm.citizenship && typeof normalizedForm.citizenship !== 'string') {
        normalizedForm.citizenship = normalizedForm.citizenship.code
      }
      if (normalizedForm.food_preference && typeof normalizedForm.food_preference !== 'number') {
        normalizedForm.food_preference = normalizedForm.food_preference.id
      }
      if (normalizedForm.residence && typeof normalizedForm.residence !== 'string') {
        normalizedForm.residence = normalizedForm.residence.code
      }
      if (normalizedForm.passport_copy && normalizedForm.passport_copy.id) {
        normalizedForm.passport_copy = normalizedForm.passport_copy.id
      } else normalizedForm.passport_copy = null
      if (normalizedForm.residence_permit_copy && normalizedForm.residence_permit_copy.id) {
        normalizedForm.residence_permit_copy = normalizedForm.residence_permit_copy.id
      } else normalizedForm.residence_permit_copy = null
      delete normalizedForm.crop.crop_url
      delete normalizedForm.crop.photo_url
      delete normalizedForm.first_name
      delete normalizedForm.last_name
      return normalizedForm
    },
  }
}
</script>

<style lang="sass" scoped>
.personal__maint
  margin-bottom: 12px
  color: #181818
  font-size: 16px
  font-weight: 600

.personal__subt
  margin-bottom: 20px
  color: #181818
  font-weight: 500

.personal__note
  display: flex
  align-items: center
  gap: 15px
  margin-bottom: 20px
  padding: 8px 10px
  border: 1px solid #ffa51f

  p
    color: #404040
    font-size: 12px
    line-height: 18px

.personal__text
  margin-bottom: 15px
  color: #676767
  font-size: 13px

.personal__last-update
  margin-bottom: 22px
  color: #959595
  font-size: 13px

.personal__block
  margin-bottom: 5px

  &:last-child
    margin-bottom: 0

.personal__header
  position: relative
  display: flex
  align-items: center
  gap: 10px
  width: 100%
  padding: 10px
  border-radius: 10px
  background-color: #f7f7f7
  transition: background-color 0.3s

  &:hover
    background-color: #f3f1f1

  &:after
    content: ''
    position: absolute
    top: 13px
    right: 17px
    width: 10px
    height: 10px
    background-image: url("~@/assets/img/accordion-arrow.svg")
    background-repeat: no-repeat
    background-position: center
    background-size: 10px
    pointer-events: none
    transition: transform 0.3s

.personal__header--show
  &:after
    transform: rotate(180deg)

.personal__title
  color: #181818
  font-weight: 500

  span
    color: #5e5e5f
    font-weight: 400

.personal__form
  margin-bottom: 30px
  padding-top: 25px

.personal__name
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 25px
  color: #181818
  font-weight: 500

.personal__name--no-indent
  margin-bottom: 0

.personal__row
  display: grid
  gap: 10px 20px

.personal__row--mix-1
  grid-template-columns: 200px 200px 154px 200px

  +max-w(990)
    grid-template-columns: 1fr 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__row--mix-2
  grid-template-columns: 200px 200px 154px 162px 154px

  +max-w(990)
    grid-template-columns: 1fr 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__row--mix-3
  grid-template-columns: 200px 82px 82px 82px 82px

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__row--mix-4
  grid-template-columns: 200px 200px 135px 135px 200px

  +max-w(990)
    grid-template-columns: 1fr 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__row--mix-5
  grid-template-columns: 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__row--mix-diet
  grid-template-columns: 200px 1fr 1fr
  align-items: flex-end

  +max-w($mobile_xl)
    grid-template-columns: 1fr

.personal__diet-switchers
  display: grid
  grid-template-columns: repeat(3, 1fr)
  align-items: flex-end
  gap: 0 20px

.personal__row--type-200
  grid-template-columns: 200px 200px 200px 200px
  margin-bottom: 20px

  +max-w(990)
    grid-template-columns: 1fr 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.personal__part
  margin-bottom: 20px

  &:last-child
    margin-bottom: 0

.personal__icon rect
  fill: #c5c5c5

.personal__icon--complited rect
  fill: $color-theme

.slide-enter-active
  transition-duration: 0.3s
  transition-timing-function: ease-in

.slide-leave-active
  transition-duration: 0.3s
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-enter-to, .slide-leave
  max-height: 768px
  overflow: hidden

.slide-enter, .slide-leave-to
  overflow: hidden
  max-height: 0

.slide-small-enter-active
  transition-duration: 0.3s
  transition-timing-function: ease-in

.slide-small-leave-active
  transition-duration: 0.3s
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-small-enter-to, .slide-small-leave
  max-height: 147px
  overflow: hidden

.slide-small-enter, .slide-small-leave-to
  overflow: hidden
  max-height: 0
</style>
