<template>
  <div class="info-text-panel">
    <div class="info-text-panel__icon">
      <img src="@/assets/img/info-text-panel-icon.svg" alt="">
    </div>
    <div class="info-text-panel__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInfoTextPanel'
}
</script>

<style lang="sass" scoped>
.info-text-panel
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 15px
  padding: 8px 0
  border-top: 1px solid #ebebeb
  border-bottom: 1px solid #ebebeb

.info-text-panel__icon
  flex-shrink: 0
  line-height: 0

.info-text-panel__text
  font-size: 13px

</style>
