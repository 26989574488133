<template>
  <div class="medical">
    <div class="medical__name">
      <img src="@/assets/img/medical.svg" alt="">
      <span>{{ $t('home.personal.med_title') }}</span>
      <img v-if="medical" src="@/assets/img/approved-icon.svg" alt="Approve">
      <img v-else src="@/assets/img/clock-snacks.svg" alt="Clock">
    </div>
    <div class="medical__controls">
      <template v-if="medical">
        <router-link :to="{ name: 'medical' }"><img src="@/assets/img/edit-table-icon.svg" alt=""></router-link>
      </template>
      <app-button v-else @click.native="onSubmitButtonClick" size="small">
        <img src="@/assets/img/submit-activities.svg" alt="Submit">
        {{ $t('home.personal.med_submit') }}
      </app-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Medical',
  props: {
    medical: {
      type: Object,
    }
  },
  methods: {
    onSubmitButtonClick() {
      this.$router.push({ name: 'medical' })
    }
  }
}
</script>

<style lang="sass" scoped>
.medical
  display: flex
  align-items: center
  justify-content: space-between
  gap: 20px
  padding: 9px 12px
  border: 1px solid #eaeaea
  border-radius: 10px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08)
  background-color: #fff

  +max-w($mobile_md)
    flex-direction: column
    align-items: flex-start

  +max-w($mobile_sm)
    gap: 10px
    padding: 15px 10px

.medical__left
  display: flex
  align-items: center
  justify-content: space-between
  gap: 20px

  +max-w($mobile_sm)
    gap: 10px

.medical__icon
  flex-shrink: 0
  line-height: 0

.medical__title
  color: #181818
  font-weight: 500

.medical__name
  flex-shrink: 0
  display: flex
  align-items: center
  gap: 10px
  line-height: 0

  span
    color: #181818
    font-weight: 500

.medical__controls
  flex-shrink: 0
  display: flex
  align-items: center
  gap: 10px

  button
    border-radius: 9px
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15)
    line-height: 0
    transition: transform 0.3s

    &:hover
      transform: translateY(-1px)

</style>
