<template>
  <vue-clip
      ref="vc"
      :options="options"
      :on-added-file="addedFile"
      :on-sending="onSending"
      :on-complete="completeFile"
      :on-removed-file="removedFile"
      class="custom-uploader"
      :class="{'custom-uploader--readonly': readonly && !innerFilePresent}"
  >
    <template slot="clip-uploader-body" v-if="innerFilePresent">
      <div class="custom-uploader__file">
        <div class="custom-uploader__body">
          <div v-if="file.file || file.status === 'success'" class="custom-uploader__part">
            <a
                :href="file.file || file.customAttributes.link"
                target="_blank"
                class="custom-uploader__filename"
            >
              {{ $t('file.show') }}
              <!--{{ file.file_name || file.name }}-->
            </a>
          </div>
          <div class="custom-uploader__part">
            <div
                v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
                class="custom-uploader__progress"
            >
              {{ Math.round(file.progress) }}%
            </div>
            <button
                v-if="!readonly"
                type="button"
                class="custom-uploader__delete"
                @click="removedFile"
            >
              <img src="@/assets/img/file/delete-uploader-icon.svg" alt="">
            </button>
          </div>
        </div>
        <div
            class="custom-uploader__line"
            v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
        >
              <span
                  class="custom-uploader__indicator"
                  :style="{ width: file.progress + '%' }"
              ></span>
        </div>
      </div>
      <div class="custom-uploader__error">{{ message }}</div>
    </template>
    <template slot="clip-uploader-action">
      <div>
        <div v-show="!innerFilePresent" class="custom-uploader__message dz-message">
          <img src="@/assets/img/file/attach-file-icon.svg" alt=""/>
          <span>{{ $t('file.attach') }}</span>
        </div>
      </div>
    </template>
  </vue-clip>
</template>

<script>
export default {
  name: 'AppUploader',
  props: {
    file_prop: Object,
    extensions: {
      type: Array,
      default: () => ['image/*', 'application/pdf']
    },
    readonly: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: `${process.env.VUE_APP_API_URL}files/`
    },
    formDataFormats: {
      type: String,
      default: 'JPEG,BMP,PNG,TIFF,PDF'
    }
  },
  model: {
    prop: 'file_prop',
    event: 'onAddedFile'
  },
  data() {
    return {
      options: {
        url: this.$props.url,
        maxFiles: {
          limit: 100,
          message: 'You cannot upload more than 1 file'
        },
        acceptedFiles: {
          extensions: this.$props.extensions,
          message: 'Wrong file format'
        },
        maxFilesize: {
          limit: 15,
          message: 'File size exceeds allowed'
        },
        uploadMultiple: false
      },
      message: '',
      progress: 0,
      file: {}
    }
  },
  computed: {
    innerFilePresent() {
      return Object.keys(this.file).length !== 0
    }
  },
  mounted() {
    // if (this.outerFilePresent) {
    //   this.file = this.$props.file_prop
    // }
  },
  watch: {
    file_prop: {
      handler(value) {
        if (value) this.file = value
      },
      immediate: true,
    }
  },
  methods: {
    addedFile(file) {
      this.file = file
    },
    onSending(file, xhr, formData) {
      formData.append('size', 15)
      formData.append('formats', this.formDataFormats)
    },
    completeFile(file, status, xhr) {
      if (file.status === 'error') {
        this.message = file.errorMessage
        this.removedFile()
        return false
      }
      const response = JSON.parse(xhr.responseText)
      file.addAttribute('link', response.file)
      this.$emit('onAddedFile', response)
      this.message = ''
    },
    removedFile() {
      this.$refs.vc.files = []
      this.file = {}
      this.$emit('onAddedFile', {})
    },
  }
}
</script>

<style lang="sass" scoped>
.custom-uploader[readonly]
  .custom-uploader__action
    display: none

  .custom-uploader__delete
    display: none

.custom-uploader--multi
  display: flex
  flex-direction: column

  .custom-uploader__action
    order: 1

  .custom-uploader__body
    margin-bottom: 10px

  .custom-uploader__message
    display: flex

.custom-uploader__message
  display: inline-flex
  align-items: center
  gap: 8px
  padding: 11px 18px 11px 12px
  border-radius: 20px
  background-color: $color-theme
  cursor: pointer

  span
    color: #fff
    font-size: 13px
    font-weight: 600

.custom-uploader__body
  display: flex
  align-items: center

.custom-uploader__line
  width: 100%
  height: 5px
  margin-top: 10px
  background-color: rgba(56, 123, 191, 0.2)
  border-radius: 50px

.custom-uploader__indicator
  display: block
  width: 0
  height: 100%
  background-color: $color-theme
  border-radius: 50px
  transition: width 0.5s

.custom-uploader__part
  display: flex
  align-items: center

.custom-uploader__img
  flex-shrink: 0
  width: 120px
  height: 120px
  margin-right: 15px

  img
    width: 100%
    height: 100%
    object-fit: cover

.custom-uploader__filename
  color: $color-theme
  font-size: 16px
  text-decoration: none
  word-break: break-word
  transition: color 0.3s

.custom-uploader__progress
  color: $color-theme
  font-size: 20px

.custom-uploader__error
  order: 2
  margin-top: 5px
  font-size: 14px
  color: $color-error

.custom-uploader__delete
  display: flex
  align-items: center
  margin-left: 15px
  color: #ea5467
  font-size: 16px
  line-height: 0
  transition: color 0.3s

  &:hover
    //transform: scale(1.05)
    color: #fe687b

  img
    width: 10px
    height: 10px
    margin-right: 15px

.custom-uploader__drop
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  padding: 30px 20px
  border: 2px dashed #6ab3ef
  border-radius: 5px
  text-align: center
  transition: border-color 0.3s, background-color 0.3s

  &:hover
    border-color: #49b1f5
    background-color: #f1f8fe

    p span
      text-decoration: underline

  p
    margin-bottom: 5px
    font-size: 16px
    color: $color-text-base

    span
      color: $color-theme
      font-size: 16px

  small
    color: #7c8db0
    font-size: 14px

.custom-uploader__extensions
  order: 3
  margin-top: 5px
  color: #7c8db0
  font-size: 14px

.custom-uploader__icon
  display: flex
  align-items: center
  margin-bottom: 5px

  span
    font-size: 16px

.custom-uploader--readonly
  pointer-events: none
</style>
