<template>
  <div class="welcome">
    <app-header />
    <div class="welcome__body">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  name: 'WelcomeLayout',
  components: { AppHeader },
  mounted() {
    localStorage.removeItem('tour_operator')
  }
}
</script>

<style lang="sass" scoped>
.welcome
  width: 100%
  background-color: #fafafa

.welcome__body
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  padding: 20px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
