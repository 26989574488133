import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Welcome from '@/views/Welcome'
import Login from '@/views/Login'
import Home from '@/views/home/Home'
import { checkClientHash } from '@/http'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: {
      layout: 'welcome',
      title: 'Welcome'
    }
  },
  {
    path: '/tips-and-recommendations',
    redirect: { name: 'tips-kilimanjaro' },
    component: () => import('@/views/tips/Index'),
    children: [
      {
        path: 'kilimanjaro',
        name: 'tips-kilimanjaro',
        component: () => import('@/views/tips/Kilimanjaro'),
        meta: {
          title: 'Kilimanjaro tips and recommendations',
          layout: 'tips',
        },
      },
      {
        path: 'kilimanjaro-marangu',
        name: 'tips-marangu',
        component: () => import('@/views/tips/Marangu'),
        meta: {
          title: 'Marangu tips and recommendations',
          layout: 'tips',
        },
      },
    ]
  },
  {
    path: '/:hash',
    component: () => import('@/views/MainLayout'),
    redirect: { name: 'login' },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          layout: 'welcome',
          title: 'Login'
        },
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: {
          title: 'Home'
        }
      },
      {
        path: 'snacks/success',
        name: 'snacks-success',
        component: () => import('@/views/SnacksSuccess'),
        meta: {
          title: 'Snacks',
          layout: 'welcome',
        }
      },
      {
        path: 'snacks/:hashRequest',
        name: 'snacks',
        component: () => import('@/views/Snacks'),
        meta: {
          title: 'Snacks'
        }
      },
      {
        path: 'rentals/:groupId',
        name: 'rentals',
        component: () => import('@/views/Rentals'),
        meta: {
          title: 'Rentals'
        }
      },
      {
        path: 'medical',
        name: 'medical',
        component: () => import('@/views/MedicalForm'),
        meta: {
          title: 'Medical clearance form'
        }
      },
    ]
  },
  {
    path: '/authorization-form/:invoice_hash',
    name: 'authorization-form',
    component: () => import('@/views/AuthorizationForm'),
    meta: {
      title: 'Authorization form'
    }
  },
  {
    path: '*',
    redirect: { name: 'welcome' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${ to.meta.title } | ${process.env.VUE_APP_IS_BRANDING === 'false' ? 'Family Altezza' : 'Africa Holiday'}`
  if (to.params.hash) {
    checkClientHash(to.params.hash)
      .then(res => {
        localStorage.setItem('content_language', res.data.content_language)
        localStorage.setItem('tour_operator', JSON.stringify(res.data.tour_operator))
        i18n.locale = res.data.content_language

        // if (!localStorage.getItem('content_language')) {
        //   localStorage.setItem('content_language', res.data.content_language)
        //   i18n.locale = res.data.content_language
        // } else {
        //   i18n.locale = localStorage.getItem('content_language')
        // }

        next()
      })
      .catch(() => { next({ name: 'welcome' }) })
  } else {
    if (!localStorage.getItem('content_language')) {
      localStorage.setItem('content_language', 'us')
    }
    next()
  }

  // if (to.params.hash) {
  //   if (to.name === 'login' && localStorage.getItem('last_name')) {
  //     next({ name: 'home', params: { hash: to.params.hash } })
  //   } else {
  //     checkClientHash(to.params.hash)
  //       .then(res => {
  //         if (!localStorage.getItem('content_language')) {
  //           localStorage.setItem('content_language', res.data.content_language)
  //           i18n.locale = res.data.content_language
  //         } else {
  //           i18n.locale = localStorage.getItem('content_language')
  //         }
  //
  //         next()
  //       })
  //       .catch(() => { next({ name: 'welcome' }) })
  //   }
  // } else {
  //   next()
  // }
})

export default router
