<template>
  <div class="table-records">
    Showing <span class="table-records__count">{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> of
    <span class="table-records__count">{{ all }}</span> records
  </div>
</template>

<script>
export default {
  name: 'AppTableRecords',
  props: {
    page: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    all: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: false,
      default: 20
    }
  },
  data() {
    return {
      limit_selected: { name: this.limit },
    }
  },
  computed: {
    lastPage() {
      return (this.$props.page * this.limit_selected.name) >= this.$props.all
    }
  },
  methods: {
    onUpdate(data) {
      this.limit_selected.name = data.name
      this.$emit('update-limiter', data.name)
    },
    returnStart() {
      if (!this.lastPage) return (this.$props.page * this.$props.count) - (this.$props.count - 1)
      return (this.$props.page * this.limit_selected.name) - this.limit_selected.name + 1
    },
    returnEnd() {
      if (!this.lastPage) return this.returnStart() + this.$props.count - 1
      return this.$props.all
    },
  }
}
</script>

<style scoped lang="sass">
.table-records
  color: $color-text-second
  font-size: 13px

  span
    color: $color-theme

</style>
