<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <notifications classes="my-notifi" width="400" :duration="5000" position="right top" />
    <notifications
        classes="my-notifi my-notifi--evolve"
        width="400"
        :duration="20000"
        position="left bottom"
        group="evolve"
    />
  </div>
</template>

<script>
export default {
  created() {
    if (this.branding === 'true') {
      const favicon16 = document.getElementById('favicon16')
      const favicon32 = document.getElementById('favicon32')
      favicon16.href = require('../public/favicon-branding/favicon-16x16.png')
      favicon32.href = require('../public/favicon-branding/favicon-32x32.png')
    }
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'default'
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
}
</script>

<style lang="sass">
#app
  display: flex
  flex-grow: 1
</style>
