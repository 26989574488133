<template>
  <div class="welcome-area">
    <img v-if="branding === 'false'" class="welcome-area__logo" src="@/assets/img/logo-main-black.svg" alt="">
    <div v-if="branding === 'false'" class="welcome-area__title">{{ $t('welcome.title') }}</div>
    <div v-if="branding === 'true'" class="welcome-area__title">{{ $t('welcome.title_branding') }}</div>
    <div class="welcome-area__text">{{ $t('welcome.text') }}</div>
  </div>
</template>

<script>

export default {
  name: 'Welcome',
  data() {
    return {

    }
  },
  created() {
    localStorage.removeItem('last_name')
    this.$i18n.locale = localStorage.getItem('content_language') || 'us'
  },
  computed: {
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    }
  }
}
</script>
