<template>
  <div class="cropper" :class="{ 'cropper--readonly': readonly }">
    <div class="cropper__wrapper" @drop.prevent="dropHandler($event)" @dragover.prevent>
      <input
          type="file"
          ref="file"
          @change="uploadImage($event)"
          accept="image/*"
      />
      <div class="cropper__holder">
        <div
          class="cropper__add"
          @click="$refs.file.click()"
          v-show="!crop_obj.crop_url"
        >
          <button type="button" @click.stop="startStream" class="cropper__webcam">
            <img src="@/assets/img/webcam-icon.svg" alt="Webcam">
          </button>
          <div class="cropper__part-top"></div>
          <div class="cropper__part-bottom">Upload Photo</div>
        </div>
        <div class="cropper__photo" v-show="crop_obj.crop_url">
          <div class="cropper__result">
            <img :src="crop_obj.crop_url" alt=""/>
          </div>
          <div class="cropper__fade">
            <button type="button" class="cropper__delete" @click="reset">
              <img src="../assets/img/cropper/delete-cropper.svg" alt=""/>
            </button>
            <div class="cropper__controls">
              <button type="button" @click="triggerFileClick">
                Add another photo
              </button>
              <button type="button" @click="showModal">Change Picture</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cropper__text">
        <div class="cropper__text-title">{{ $t('home.personal.photo.title') }}</div>
        <ul class="cropper__text-list">
          <li>{{ $t('home.personal.photo.text') }}</li>
<!--          <li>{{ $t('home.personal.photo.text1') }}</li>-->
          <li>{{ $t('home.personal.photo.text2') }}</li>
          <li>{{ $t('home.personal.photo.text3') }}</li>
        </ul>
      </div>
      <modal
          name="modal"
          :width="900"
          :height="'auto'"
          :scrollable="true"
          :adaptive="true"
      >
        <div class="modal">
          <div class="modal__title">Upload picture</div>
          <div class="modal__body">
            <div class="modal__content">
              <div class="modal__picture">
                <div class="modal__controls">
                  <button @click="$refs.cropper.rotate(-90)" type="button">
                    <img src="@/assets/img/rotate-arrow-to-the-left.svg" alt="">
                  </button>
<!--                  <button @click="$refs.cropper.scale(1.5)" type="button">-->
<!--                    <img src="@/assets/img/rotate-arrow-to-the-left.svg" alt="">-->
<!--                  </button>-->
                  <button @click="$refs.cropper.rotate(90)" type="button">
                    <img src="@/assets/img/rotate-arrow-to-the-right.svg" alt="">
                  </button>
                </div>
                <div v-show="isImgLoading" class="loading loading--cropper" id="loading">
                  <div class="loading__body">
                    <div class="effect-1 loading__effects"></div>
                    <div class="effect-2 loading__effects"></div>
                    <div class="effect-3 loading__effects"></div>
                  </div>
                </div>
                <template v-if="!isImgLoading">
                  <vue-cropper
                    ref="cropper"
                    :aspect-ratio="aspectRatio"
                    :view-mode="2"
                    :responsive="true"
                    :guides="false"
                    :center="false"
                    :src="crop_obj.photo_url"
                    class="new-cropper"
                  />
                </template>

              </div>
              <div class="modal__footer">
                <button @click="triggerFileClick" type="button">
                  <img src="@/assets/img/cropper/upload-another.svg" alt="">
                  <span>Upload another picture</span>
                </button>
                <app-button ref="save_crop" @click.native.once="crop" type="button">
                  <span>Save</span>
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal
        name="modal-web"
        :width="600"
        :height="'auto'"
        :scrollable="true"
        :adaptive="true"
      >
        <div class="modal">
          <div class="modal__title">Take a photo</div>
          <div class="modal__body">
            <div class="modal__content">
              <div class="modal__picture">
                <canvas width="600" height="450" class="cropper__canvas" ref="canvas"></canvas>
                <video class="cropper__video" ref="video"></video>
              </div>
              <app-cells position="center">
                <app-button @click.native="takePicture" type="button">
                  <span>Save</span>
                </app-button>
              </app-cells>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <div class="cropper__message" v-if="!isImageFlag">
      Please select the correct file format, files are accepted <b>JPG, PNG, BMP</b>
    </div>
    <div class="cropper__message" v-if="!isSizeFlag">
      File size must not exceed <b>2 MB</b>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { postFile } from '@/http'

export default {
  name: 'CropperModal',
  components: {
    VueCropper,
  },
  model: {
    prop: 'value',
    event: 'sendData'
  },
  props: ['aspectRatio', 'value', 'readonly'],
  data() {
    return {
      crop_obj: {
        photo_url: null,
        crop_url: null,
      },
      isImageFlag: true,
      isSizeFlag: true,
      isImgLoading: false,
      dropped_file: null,
    }
  },
  beforeDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop()
      })
    }
  },
  watch: {
    value: {
      handler(obj) {
        if (obj) this.crop_obj = obj
      },
      immediate: true,
    }
  },
  methods: {
    takePicture() {
      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.video, 0, 0, 600, 450)
      this.crop_obj.photo_url = this.$refs.canvas.toDataURL('image/jpeg')
      this.$modal.hide('modal-web')
      this.$modal.show('modal')
    },
    startStream() {
      this.$modal.show('modal-web')
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.stream = stream
          this.$refs.video.srcObject = this.stream
          this.$refs.video.play()
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Connect your webcam',
          })
          this.$modal.hide('modal-web')
        })
    },
    showModal() {
      this.$modal.show('modal')
    },
    hideModal() {
      this.$modal.hide('modal')
    },
    reset() {
      this.crop_obj.photo_url = null
      this.crop_obj.crop_url = null
      this.crop_obj.photo = null
      this.dropped_file = null
    },
    getExtension(filename) {
      const parts = filename.split('.')
      return parts[parts.length - 1]
    },
    isImage(filename) {
      const ext = this.getExtension(filename)
      switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'png':
      case 'tiff':
      case 'tif':
        return true
      }
      return false
    },
    uploadImage(event) {
      this.reset()
      this.isImgLoading = true
      const input = event.target

      if (input.files && input.files[0]) {
        this.processingFileUpload(input.files[0])
      }
    },
    processingFileUpload(file) {
      if (this.isImage(file.name)) {
        this.isImageFlag = true

        if (file.size / 1000 < 5100) {
          const reader = new FileReader()

          this.isSizeFlag = true

          reader.onload = e => {
            this.crop_obj.photo_url = e.target.result
            this.isImgLoading = false
            setTimeout(() => {
              if (this.$refs.cropper.getData().rotate !== 0) this.$refs.cropper.rotateTo(0)
            }, 0)
          }
          reader.readAsDataURL(file)
          this.showModal('modal')
        } else {
          this.isSizeFlag = false
          this.$modal.hide('modal')
        }
      } else {
        this.isImageFlag = false
        this.$modal.hide('modal')
      }
    },
    crop() {
      this.$refs.save_crop.preload = true
      this.crop_obj.crop_url = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.crop_obj.coords = {}
      const coords = this.$refs.cropper.getData()
      this.crop_obj.coords.width = coords.width
      this.crop_obj.coords.height = coords.height
      this.crop_obj.coords.top = coords.y
      this.crop_obj.coords.left = coords.x
      this.crop_obj.coords.rotate = coords.rotate

      this.$emit('sendData', { crop_url: this.crop_obj.crop_url, photo_url: this.crop_obj.photo_url, coords: this.crop_obj.coords, photo: this.crop_obj.photo })
      this.hideModal('modal')
    },
    sendCrop() {
      return new Promise(resolve => {
        const formData = new FormData()

        if (this.stream) {
          formData.append('file', this.dataURLtoFile(this.crop_obj.photo_url, 'file'))
        } else {
          if (this.dropped_file) formData.append('file', this.dropped_file)
          else formData.append('file', this.$refs.file.files[0])
        }

        formData.append('size', 6)
        formData.append('formats', 'JPEG,BMP,PNG,TIFF')

        postFile(formData)
          .then(response => {
            this.crop_obj.photo = response.data.id
            this.$emit('sendData',
              {
                photo: this.crop_obj.photo,
                coords: this.crop_obj.coords,
                crop_url: this.crop_obj.crop_url,
                photo_url: this.crop_obj.photo_url,
              }
            )
            resolve()
          })
          .catch(error => console.error(error))
      })

    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    triggerFileClick() {
      this.$refs.file.click()
    },
    onCropperReady() {
      this.isImgLoading = false
    },
    dropHandler(event) {
      if (event.dataTransfer.items[0].kind === 'file') {
        this.reset()
        this.isImgLoading = true
        this.dropped_file = event.dataTransfer.items[0].getAsFile()
        this.processingFileUpload(this.dropped_file)
      }
    }
  }
}
</script>

<style lang="sass">
.new-cropper
  max-height: 70vh
  background-color: rgba(#000, 0.4)

.cropper
  .vue-advanced-cropper
    max-height: 70vh
    background-color: rgba(#000, 0.4)

    .vue-simple-line
      border-color: #ff9416

    .vue-simple-handler
      width: 16px
      height: 16px
      border-radius: 50%
      background-color: #ff9416

  .vue-advanced-cropper__background
    background: transparent

.cropper--readonly
  pointer-events: none

  .cropper__info
    opacity: 0.5

.cropper--square
  .cropper__add
    width: 160px
    height: 160px

  .cropper__photo
    width: 160px
    height: 160px

  .cropper__text
    display: none

.cropper__wrapper
  display: flex
  align-items: center
  gap: 30px

  +max-w($mobile_md)
    flex-direction: column
    align-items: flex-start

  > input
    +visually-hidden

.cropper__text-title
  margin-bottom: 20px
  font-size: 13px
  font-weight: 500

.cropper__text-list
  max-width: 460px

  li
    position: relative
    margin-bottom: 10px
    padding-left: 13px
    color: #5f5f5f
    font-size: 13px
    line-height: 17px

    &:last-child
      margin-bottom: 0

    &:before
      content: ""
      position: absolute
      left: 0
      top: 7px
      width: 3px
      height: 3px
      border-radius: 50%
      background-color: #5f5f5f

.cropper__result img
  width: 100%

.cropper__result
  height: 100%
  line-height: 0

  img
    width: 100%
    height: 100%
    object-fit: cover

.cropper__photo
  flex-shrink: 0
  order: -1
  position: relative
  width: 144px
  height: 192px
  border-radius: 10px
  overflow: hidden

  &:hover
    .cropper__fade
      opacity: 1

.cropper__fade
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  transition: opacity 0.3s

.cropper__delete
  position: absolute
  top: 6px
  right: 6px
  width: 26px
  height: 26px
  line-height: 0
  opacity: 0.8
  transition: opacity 0.3s

  &:hover
    opacity: 1

  img
    width: 100%

.cropper__controls
  position: absolute
  bottom: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  padding: 15px 0
  background-color: transparent

  button
    padding: 3px 8px
    border-radius: 20px
    background-color: #fff
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15)
    color: $color-text-second
    font-size: 13px
    font-weight: 600
    text-align: center
    transition: color 0.3s

    &:first-child
      margin-bottom: 10px

    &:hover
      color: #404040

.cropper__add
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex-shrink: 0
  order: -1
  width: 144px
  height: 192px
  overflow: hidden
  border: 1px dashed transparent
  border-radius: 10px
  background-color: #f7f7f7
  line-height: 0
  cursor: pointer
  transition: border-color 0.3s

  &:hover
    border-color: #c7c7c7

    .cropper__part-bottom
      opacity: 1

    .cropper__part-top
      opacity: 1

.cropper__part-top
  width: 26px
  height: 26px
  margin-bottom: 12px
  background-image: url("~@/assets/img/cropper/pic-icon.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 26px
  opacity: 0.5
  transition: opacity 0.3s

.cropper__part-bottom
  font-size: 13px
  font-weight: 600
  line-height: 17px
  color: #404040
  opacity: 0.6
  text-align: center
  transition: opacity 0.3s

.cropper__info
  padding: 0 20px

  p
    margin-bottom: 10px
    font-size: 16px
    font-weight: 600
    color: $color-text-base

  li
    position: relative
    margin-bottom: 8px
    padding-left: 20px
    font-size: 14px
    font-weight: 600
    line-height: 22px

    &:last-child
      margin-bottom: 0

    &::before
      content: ""
      position: absolute
      top: 5px
      left: 0
      width: 11px
      height: 11px
      border-radius: 50%
      border: 3px solid $color-theme
      background-color: #fff
      box-shadow: 0 4px 10px rgba(56, 123, 191, 0.2)

.cropper__message
  margin-top: 5px
  font-size: 13px
  color: $color-error

.cropper__webcam
  position: absolute
  top: 10px
  right: 10px
  line-height: 0
  opacity: 0.6
  transition: opacity 0.3s

  &:hover
    opacity: 1

.cropper__canvas
  display: none

.cropper__video
  display: block
  width: 100%

.cropper-point
  width: 16px
  height: 16px
  border-radius: 50%
  background-color: #ff9416
  opacity: 1

  &.point-e
    right: -8px

  &.point-w
    left: -8px

  &.point-n
    top: -8px

  &.point-s
    bottom: -8px

  &.point-ne
    top: -8px
    right: -8px

  &.point-nw
    top: -8px
    left: -8px

  &.point-sw
    bottom: -8px
    left: -8px

  &.point-se
    bottom: -8px
    right: -8px
    width: 16px
    height: 16px
    opacity: 1

.cropper-view-box
  outline: none

.cropper-line
  background-color: #ff9416
  opacity: 1

  &.line-e
    width: 1px
    right: -1px

  &.line-w
    width: 1px
    left: -1px

  &.line-n
    top: 0
    height: 1px

  &.line-s
    bottom: 0
    height: 1px
</style>
