<template>
  <header class="header">
    <div class="container">
      <div class="header__body">
        <template v-if="branding === 'false'">
          <router-link v-if="$route.params.hash" :to="{ name: 'home', params: { hash: $route.params.hash } }" class="header__logo">
            <img src="@/assets/img/logo-main-black.svg" alt="">
          </router-link>
          <img v-else src="@/assets/img/logo-main-black.svg" alt="">
        </template>
        <div v-if="branding === 'true'" class="header__operator">
          <span v-if="tourOperator">{{ tourOperator }}</span>
        </div>
        <app-lang-switcher v-if="branding === 'false' && this.$route.name !== 'authorization-form'" />
      </div>
    </div>
  </header>
</template>

<script>
import AppLangSwitcher from '@/components/AppLangSwitcher'

export default {
  name: 'AppHeader',
  components: { AppLangSwitcher },
  computed: {
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
    tourOperator() {
      return JSON.parse(localStorage.getItem('tour_operator'))
    }
  },
}
</script>

<style lang="sass" scoped>
.header
  position: absolute
  top: 0
  left: 0
  width: 100%
  background-color: #fff
  border-bottom: 1px solid #e8e8e8

.header--tips
  .container
    max-width: unset
    padding: 0 15px 0 40px

    +max-w($laptop_md)
      padding: 0 15px

.header__body
  height: 56px
  display: flex
  align-items: center
  justify-content: space-between

.header__operator
  font-size: 17px
  font-weight: 600
</style>
