<template>
  <div class="content">
    <app-spinner v-if="!is_loaded" />
    <div v-else class="home">
      <div class="home__title">
        <span v-if="branding === 'false'">{{ $t('home.welcome.title') }}</span>
        <span v-if="branding === 'true'">{{ $t('home.welcome.title_branding') }}</span>
        <a
            v-if="branding === 'false' && home_data.itinerary_hash"
            :href="`https://story.altezza.travel/itinerary/${home_data.itinerary_hash}`"
            target="_blank"
            class="btn btn--default"
        >
          <img src="@/assets/img/doc-icon.svg" alt="Doc">
          {{ $t('home.welcome.itinerary') }}
        </a>
      </div>
      <div class="home__subt">
        {{ $t('home.welcome.name') }} {{ home_data.personal_detail.first_name || '' }} {{ home_data.personal_detail.last_name }}
      </div>
      <div v-if="branding === 'false'" class="home__text">{{ $t('home.welcome.text') }}</div>
      <div v-if="branding === 'true'" class="home__text">{{ $t('home.welcome.text_branding') }}</div>
      <section class="home__section home__section--flight">
        <home-flight :transport="home_data.transport" :personal="home_data.personal_detail" @update="getHomeData" />
        <home-evolve :level="home_data.info.level" />
      </section>
      <section v-if="branding === 'false'" class="home__section home__section--managers">
        <home-managers :created_by="home_data.group.created_by" :managers="home_data.group.shared_with" :hash="home_data.hash" />
      </section>
      <section class="home__section home__section--personal">
        <home-personal
          @getHomeData="getHomeData"
          :personal="home_data.personal_detail"
          :climbing_insurance="home_data.climbing_insurance"
          :safari_insurance="home_data.safari_insurance"
          :climbing_insurance_required="home_data.requirements.climbing_insurance_required"
          :safari_insurance_required="home_data.requirements.safari_insurance_required"
          :medical_clearance_required="home_data.requirements.medical_clearance_required"
          :days_before_arrival="home_data.info.days_before_arrival"
          :requirements="home_data.requirements"
        />
      </section>
      <section v-if="home_data.requirements.medical_clearance_required" class="home__section home__section--medical">
        <home-medical :medical="home_data.medical_clearance" />
      </section>
      <section class="home__section home__section--activities">
        <div class="frame-tabs">
          <div class="frame-tabs__controls">
            <button
                @click="tabs_activities = true"
                class="frame-tabs__button"
                :class="{ 'frame-tabs__button--active': tabs_activities }"
            >
              {{ $t('home.activities.tabs.tab_1') }}
            </button>
          </div>
          <div class="frame-tabs__content">
            <transition name="fade" mode="out-in">
              <template v-if="tabs_activities">
                <home-activities :activities="home_data.activities" :hash="home_data.hash" />
              </template>
              <template v-else>Content of another tab</template>
            </transition>
          </div>
        </div>
      </section>
    </div>
    <modal
        name="evolve-level5-modal"
        :width="400"
        :height="'auto'"
        :scrollable="true"
        :adaptive="true"
    >
      <div class="modal modal-evolve">
        <img src="@/assets/img/evolve/evolve-level5-big.png" alt="" class="modal-evolve__pic">
        <div class="modal-evolve__title">
          <img src="@/assets/img/evolve/crown-big-icon.svg" alt="">
          <span>{{ $t('home.evolve.modal_title') }}</span>
        </div>
        <div class="modal-evolve__text">
          {{ $t('home.evolve.modal_text_1') }}<br>{{ $t('home.evolve.modal_text_2') }}
        </div>
        <app-cells position="center" :indent="false">
          <app-button @click.native="$modal.hide('evolve-level5-modal')">
            {{ $t('home.evolve.modal_button') }}
          </app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { login } from '@/http'
import HomeActivities from '@/views/home/Activities'
import HomeMedical from '@/views/home/Medical'
import HomePersonal from '@/views/home/Personal'
import HomeEvolve from '@/views/home/Evolve'
import HomeFlight from '@/views/home/Flight'
import HomeManagers from '@/views/home/Managers'
import { parse } from 'date-fns'

export default {
  name: 'Home',
  components: { HomePersonal, HomeActivities, HomeMedical, HomeEvolve, HomeFlight, HomeManagers },
  data() {
    return {
      is_loaded: false,
      tabs_activities: true,
      home_data: {
        activities: [],
        medical_clearance: null,
      },
    }
  },
  created() {
    if (!this.getUserLastName) this.$router.push({ name: 'login' })
    else {
      login(this.$route.params.hash, this.getUserLastName)
        .then(res => {
          this.home_data = res.data
          this.is_loaded = true
          if (!JSON.parse(localStorage.getItem('user_level'))) localStorage.setItem('user_level', res.data.info.level)
          else {
            if (JSON.parse(localStorage.getItem('user_level')) !== res.data.info.level) {
              if (res.data.info.level === 5) this.$modal.show('evolve-level5-modal')
              else {
                this.$notify({
                  group: 'evolve',
                  type: `level_${res.data.info.level}`,
                  title: `${this.$t(`home.evolve.alert_${res.data.info.level}_title`)}`,
                  text: `${this.$t(`home.evolve.alert_${res.data.info.level}_text`)}`
                })
              }
              localStorage.setItem('user_level', res.data.info.level)
            }
          }
        })
        .catch(() => { this.$router.push({ name: 'welcome' }) })
    }
  },
  computed: {
    getUserLastName() {
      return localStorage.getItem('last_name')
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
  methods: {
    dateFormatting(date) {
      return parse(date, 'yyyy-MM-dd', new Date())
    },
    getHomeData() {
      login(this.$route.params.hash, this.getUserLastName)
        .then(res => {
          this.home_data = res.data
          this.is_loaded = true
          if (JSON.parse(localStorage.getItem('user_level')) !== res.data.info.level) {
            this.$notify({
              group: 'evolve',
              type: `level_${res.data.info.level}`,
              title: `${this.$t(`home.evolve.alert_${res.data.info.level}_title`)}`,
              text: `${this.$t(`home.evolve.alert_${res.data.info.level}_text`)}`
            })
            if (res.data.info.level === 5) this.$modal.show('evolve-level5-modal')
            localStorage.setItem('user_level', res.data.info.level)
          }
        })
        .catch(() => { this.$router.push({ name: 'welcome' }) })
    },
  }
}
</script>

<style lang="sass" scoped>
.home__title
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 45px
  color: #181818
  font-size: 22px
  font-weight: 600

  +max-w($mobile_lg)
    flex-direction: column
    gap: 20px

  +max-w($mobile_sm)
    align-items: stretch
    margin-bottom: 20px
    font-size: 20px

.home__subt
  margin-bottom: 15px
  color: #181818
  font-size: 18px
  font-weight: 600

  +max-w($mobile_sm)
    font-size: 15px

.home__text
  margin-bottom: 30px
  color: #181818
  font-size: 13px
  line-height: 21px

.home__section--flight
  display: flex
  align-items: center
  gap: 20px
  margin-bottom: 46px

  +max-w($laptop)
    flex-direction: column

.home__section--managers
  margin-bottom: 46px

.home__section--personal
  margin-bottom: 40px

.home__section--activities
  margin-bottom: 70px

.home__section--medical
  margin-bottom: 40px

</style>
