import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import VueTables from 'vue-tables-2-premium'
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate'
const VueInputMask = require('vue-inputmask').default
import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip'
import DatePicker from 'vue2-datepicker'
import VueClip from 'vue-clip'
import VueCardFormat from 'vue-credit-card-validation'

import DefaultLayout from './layouts/Default'
import WelcomeLayout from './layouts/Welcome'
import TipsLayout from './layouts/Tips.vue'

Vue.component('default', DefaultLayout)
Vue.component('welcome', WelcomeLayout)
Vue.component('tips', TipsLayout)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('DatePicker', DatePicker)

Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueTables.ClientTable)
Vue.use(VueInputMask)
Vue.use(VModal)
Vue.use(VTooltip, {
  defaultHtml: false,
})
Vue.use(VueClip)
Vue.use(VueCardFormat)
