<template>
  <div class="flight">
    <div class="flight__holder">
      <div class="flight__part">
        <div class="flight__icon">
          <img :src="require(`@/assets/img/${getArrivalType}-icon.svg`)" alt="Arrival icon">
        </div>
        <div class="flight__info">
          <div class="flight__title">
            <app-cells :indent="false">
              <div class="flight__icons">
                <span>{{ $t('home.flight.title_arr') }}</span>
                <img
                    v-if="transport.arrival.arrival_confirmed"
                    src="@/assets/img/flight-confirmed.svg"
                    alt="Confirmed"
                    v-tooltip="{content: $t('home.flight.confirmed'), placement: 'top', offset: 10}"
                >
                <img
                    v-if="transport.arrival.arrival_flight_domestic"
                    src="@/assets/img/glyph-icon.svg"
                    alt=""
                    v-tooltip="{content: $t('home.flight.form-domestic'), placement: 'top', offset: 10}"
                >
              </div>
            </app-cells>
            <button
              v-if="!transport.arrival.arrival_confirmed && transport.arrival.arrival_method"
              @click="onShowModal('arrival')"
              class="flight__button"
            >
              {{ $t('home.flight.button') }}
            </button>
          </div>
          <div class="flight__date">
            <span v-if="transport.arrival.arrival_method">
              {{ formatDate(transport.arrival.arrival_date) }}, {{ transport.arrival.arrival_time }}
            </span>
            <span v-else>{{ $t('home.flight.no_info') }}</span>
          </div>
          <span v-if="!transport.arrival.arrival_method" class="flight__note flight__note--red">{{ $t('home.flight.please_arr') }}</span>
          <button
            v-if="!transport.arrival.arrival_method"
            @click="onShowModal('arrival')"
            class="flight__button flight__button--add"
          >
            {{ $t('home.flight.add') }}
          </button>
          <div v-if="transport.arrival.arrival_company" class="flight__name">
            {{ transport.arrival.arrival_company.name }}
            <span v-if="transport.arrival.arrival_flight_number"> - {{ transport.arrival.arrival_flight_number }}</span>
          </div>
          <div class="flight__location">
            {{ transport.arrival.arrival_location ? transport.arrival.arrival_location.name : '' }}
          </div>
          <button
            v-if="transport.arrival.arrival_method && transport.arrival.arrival_method.code === 'flight' && transport.arrival.arrival_ticket"
            @click="onDownloadTickets(transport.arrival.arrival_ticket, 'Arrival tickets')"
            type="button"
            class="flight__download"
          >
            <img src="@/assets/img/download-icon.svg" alt="Download">
            <span>{{ $t('home.flight.download') }}</span>
          </button>
        </div>
      </div>
      <div class="flight__part">
        <div class="flight__icon">
          <img :src="require(`@/assets/img/${getDepartureType}-icon.svg`)" alt="Arrival icon">
        </div>
        <div class="flight__info">
          <div class="flight__title">
            <div class="flight__icons">
              <span>{{ $t('home.flight.title_dep') }}</span>
              <img
                  v-if="transport.departure.departure_confirmed"
                  src="@/assets/img/flight-confirmed.svg"
                  alt="Confirmed"
                  v-tooltip="{content: $t('home.flight.confirmed'), placement: 'top', offset: 10}"
              >
              <img
                  v-if="transport.departure.departure_flight_domestic"
                  src="@/assets/img/glyph-icon.svg"
                  alt=""
                  v-tooltip="{content: $t('home.flight.form-domestic'), placement: 'top', offset: 10}"
              >
              <img
                  v-if="transport.departure.departure_pcr_required"
                  src="@/assets/img/pcr-icon.svg"
                  alt=""
                  v-tooltip="{content: $t('home.flight.tooltip-pcr'), placement: 'top', offset: 10}"
              >
            </div>
            <button
              v-if="!transport.departure.departure_confirmed && transport.departure.departure_method"
              @click="onShowModal('departure')"
              class="flight__button"
            >
              {{ $t('home.flight.button') }}
            </button>
          </div>
          <div class="flight__date">
            <span v-if="transport.departure.departure_method">
              {{ formatDate(transport.departure.departure_date) }}, {{ transport.departure.departure_time }}
            </span>
            <span v-else>{{ $t('home.flight.no_info') }}</span>
          </div>
          <span v-if="!transport.departure.departure_method" class="flight__note flight__note--red">{{ $t('home.flight.please_dep') }}</span>
          <button
            v-if="!transport.departure.departure_method"
            @click="onShowModal('departure')"
            class="flight__button flight__button--add"
          >
            {{ $t('home.flight.add') }}
          </button>
          <div v-if="transport.departure.departure_company" class="flight__name">
            {{ transport.departure.departure_company.name }}
            <span v-if="transport.departure.departure_flight_number"> - {{ transport.departure.departure_flight_number }}</span>
          </div>
          <div class="flight__location">
            {{ transport.departure.departure_location ? transport.departure.departure_location.name : '' }}
          </div>
          <button
            v-if="transport.departure.departure_method && transport.departure.departure_method.code === 'flight' && transport.departure.departure_ticket"
            @click="onDownloadTickets(transport.departure.departure_ticket, 'Departure tickets')"
            type="button"
            class="flight__download"
          >
            <img src="@/assets/img/download-icon.svg" alt="Download">
            <span>{{ $t('home.flight.download') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="transport.arrival.arrival_notes || transport.departure.departure_notes || (transport.arrival.arrival_confirmed && transport.departure.departure_confirmed)"
      class="flight__footer"
    >
      <div class="flight__footer-left">
        <button
          v-if="transport.arrival.arrival_notes || transport.departure.departure_notes"
          class="flight__additional"
          v-tooltip="{content: tooltipContent, placement: 'top-start', offset: 10, html: true}"
        >
          <img src="@/assets/img/flight-note.svg" alt="Additional">
          <span>{{ $t('home.flight.additional') }}</span>
        </button>
      </div>
      <div v-if="transport.arrival.arrival_confirmed && transport.departure.departure_confirmed" class="flight__footer-right">
        <span class="flight__text">{{ $t('home.flight.mistake') }}</span>
        <span
          class="flight__note"
        >
          {{ $t('home.flight.contact-manager') }}
        </span>
      </div>
    </div>
    <modal
      name="transport-modal"
      :width="774"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal-transport">
        <button @click="$modal.hide('transport-modal')" class="modal-transport__close" type="button"></button>
        <div v-show="transport_modal_type === 'arrival'" class="modal-transport__part">
          <div class="modal-transport__top">
            <div class="modal-transport__title">
              <img :src="require(`@/assets/img/${getArrivalTypeModal}-icon.svg`)" alt="Arrival icon">
              <span>{{ $t('home.flight.title_arr_popup') }}</span>
            </div>
          </div>
          <app-info-text-panel>
            <template v-if="branding === 'false'">
              {{ $t('home.flight.info_panel_arrival') }}
            </template>
            <template v-if="branding === 'true'">
              {{ $t('home.flight.info_panel_arrival_branding') }}
            </template>
          </app-info-text-panel>
          <div class="modal-transport__row modal-transport__row--1">
            <app-form-group :label="$t('home.flight.form-transport')" required>
              <v-select
                v-model="form.arrival_method"
                :reduce="item => item.code"
                :options="transport_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                @input="onChangeTransportMethod('arrival')"
                label="name"
                :placeholder="$t('home.flight.form-transport')"
                class="select"
                :class="{'select--error': $v.form.arrival_method.$error}"
                :readonly="form.arrival_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.arrival_method.$dirty && !$v.form.arrival_method.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-date')" required>
              <date-picker
                v-model="form.arrival_date"
                format="DD MMM YYYY"
                value-type="YYYY-MM-DD"
                :placeholder="$t('home.flight.form-date')"
                class="datepicker"
                popup-class="datepicker__popup"
                title-format="DD MMM YYYY"
                :lang="getCurrentLang"
                :class="{'datepicker--error': $v.form.arrival_date.$error}"
                :readonly="form.arrival_confirmed"
              />
              <template #error>
                <div v-if="$v.form.arrival_date.$dirty && !$v.form.arrival_date.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.arrival_date.$dirty && !$v.form.arrival_date.minValue">
                  {{ $t('validations.date_min_today') }}
                </div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-time')" label-for="arrival-time" required>
              <app-input
                v-model="form.arrival_time"
                v-mask="'99:99'"
                :placeholder="$t('home.flight.form-time')"
                autocomplete="off"
                id="arrival-time"
                @paste.native.prevent
                :error="$v.form.arrival_time.$error"
                :readonly="form.arrival_confirmed"
                type="time"
              />
              <template #error>
                <div v-if="$v.form.arrival_time.$dirty && !$v.form.arrival_time.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.arrival_time.$dirty && !$v.form.arrival_time.timeRegex">{{ $t('validations.incorrect-time') }}</div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-location')" required>
              <v-select
                @input="onArrivalLocationSelected"
                v-model="form.arrival_location"
                :options="location_options"
                :filterable="true"
                :clearable="false"
                :searchable="true"
                @search="onLocationSearch"
                label="name"
                :placeholder="$t('home.flight.form-location')"
                class="select"
                :class="{'select--error': $v.form.arrival_location.$error}"
                :readonly="form.arrival_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #no-options="{ search, searching }">
                  <div v-if="searching" class="select__noty">
                    {{ $t('select-text.location-searching') }}
                  </div>
                  <div v-else class="select__noty">
                    {{ $t('select-text.location') }}
                  </div>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.arrival_location.$dirty && !$v.form.arrival_location.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
          </div>
          <div class="modal-transport__row modal-transport__row--2">
            <app-form-group
              v-show="form.arrival_method === 'flight' || form.arrival_method === 'bus'"
              :label="form.arrival_method === 'flight' ? $t('home.flight.form-airlines') : $t('home.flight.form-bus')"
              required
            >
              <v-select
                v-model="form.arrival_company"
                :reduce="item => item.id"
                :options="company_options.filter(item => item.type === form.arrival_method)"
                :filterable="true"
                :clearable="false"
                :searchable="true"
                label="name"
                :placeholder="form.arrival_method === 'flight' ? $t('home.flight.form-airlines') : $t('home.flight.form-bus')"
                class="select"
                :class="{'select--error': $v.form.arrival_company.$error}"
                :readonly="form.arrival_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.arrival_company.$dirty && !$v.form.arrival_company.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
            <app-form-group v-show="form.arrival_method === 'flight'" :label="$t('home.flight.form-number')" label-for="arrival-flight" required>
              <app-input
                v-model="form.arrival_flight_number"
                :placeholder="$t('home.flight.form-number')"
                id="arrival-flight"
                :error="$v.form.arrival_flight_number.$error"
                :readonly="form.arrival_confirmed"
              />
              <template #error>
                <div v-if="$v.form.arrival_flight_number.$dirty && !$v.form.arrival_flight_number.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.arrival_flight_number.$dirty && !$v.form.arrival_flight_number.flightNumberRegex">{{ $t('validations.only-integer-en') }}</div>
              </template>
            </app-form-group>
            <app-form-group v-show="form.arrival_method === 'flight'" :label="$t('home.flight.form-domestic')" class="form-group--switcher">
              <app-switcher v-model="form.arrival_flight_domestic" :readonly="form.arrival_confirmed" />
            </app-form-group>
          </div>
          <div class="modal-transport__row">
            <app-form-group :label="$t('home.flight.form-notes')" label-for="arrival-notes">
              <app-input
                  v-model="form.arrival_notes"
                  :placeholder="$t('home.flight.form-notes')"
                  id="arrival-notes"
                  :readonly="form.arrival_confirmed"
              />
            </app-form-group>
          </div>
          <app-form-group v-show="form.arrival_method === 'flight'" :label="$t('home.flight.tickets')">
            <app-uploader v-model="form.arrival_ticket" :extensions="['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']" />
          </app-form-group>
        </div>
        <div v-show="transport_modal_type === 'departure'" class="modal-transport__part">
          <div class="modal-transport__top">
            <div class="modal-transport__title">
              <img :src="require(`@/assets/img/${getDepartureTypeModal}-icon.svg`)" alt="Arrival icon">
              <span>{{ $t('home.flight.title_dep_popup') }}</span>
            </div>
          </div>
          <app-info-text-panel>
            <template v-if="branding === 'false'">
              {{ $t('home.flight.info_panel_departure') }}
            </template>
            <template v-if="branding === 'true'">
              {{ $t('home.flight.info_panel_departure_branding') }}
            </template>
          </app-info-text-panel>
          <div class="modal-transport__row modal-transport__row--1">
            <app-form-group :label="$t('home.flight.form-transport')" required>
              <v-select
                v-model="form.departure_method"
                :reduce="item => item.code"
                :options="transport_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                @input="onChangeTransportMethod('departure')"
                label="name"
                :placeholder="$t('home.flight.form-transport')"
                class="select"
                :class="{'select--error': $v.form.departure_method.$error}"
                :readonly="form.departure_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.departure_method.$dirty && !$v.form.departure_method.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-date')" required>
              <date-picker
                v-model="form.departure_date"
                format="DD MMM YYYY"
                value-type="YYYY-MM-DD"
                :placeholder="$t('home.flight.form-date')"
                class="datepicker"
                popup-class="datepicker__popup"
                title-format="DD MMM YYYY"
                :lang="getCurrentLang"
                :class="{'datepicker--error': $v.form.departure_date.$error}"
                :readonly="form.departure_confirmed"
              />
              <template #error>
                <div v-if="$v.form.departure_date.$dirty && !$v.form.departure_date.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.departure_date.$dirty && !$v.form.departure_date.minValue">
                  {{ $t('validations.date_min_today') }}
                </div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-time')" label-for="arrival-time" required>
              <app-input
                v-model="form.departure_time"
                v-mask="'99:99'"
                :placeholder="$t('home.flight.form-time')"
                autocomplete="off"
                id="arrival-time"
                @paste.native.prevent
                :error="$v.form.departure_time.$error"
                :readonly="form.departure_confirmed"
                type="time"
              />
              <template #error>
                <div v-if="$v.form.departure_time.$dirty && !$v.form.departure_time.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.departure_time.$dirty && !$v.form.departure_time.timeRegex">{{ $t('validations.incorrect-time') }}</div>
              </template>
            </app-form-group>
            <app-form-group :label="$t('home.flight.form-location')" required>
              <v-select
                @input="onDepartureLocationSelected"
                v-model="form.departure_location"
                :options="location_options"
                :filterable="true"
                :clearable="false"
                :searchable="true"
                @search="onLocationSearch"
                label="name"
                :placeholder="$t('home.flight.form-location')"
                class="select"
                :class="{'select--error': $v.form.departure_location.$error}"
                :readonly="form.departure_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #no-options="{ search, searching }">
                  <div v-if="searching" class="select__noty">
                    {{ $t('select-text.location-searching') }}
                  </div>
                  <div v-else class="select__noty">
                    {{ $t('select-text.location') }}
                  </div>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.departure_location.$dirty && !$v.form.departure_location.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
          </div>
          <div class="modal-transport__row modal-transport__row--2">
            <app-form-group
              v-show="form.departure_method === 'flight' || form.departure_method === 'bus'"
              :label="form.departure_method === 'flight' ? $t('home.flight.form-airlines') : $t('home.flight.form-bus')"
              required
            >
              <v-select
                v-model="form.departure_company"
                :reduce="item => item.id"
                :options="company_options.filter(item => item.type === form.departure_method)"
                :filterable="true"
                :clearable="false"
                :searchable="true"
                label="name"
                :placeholder="form.departure_method === 'flight' ? $t('home.flight.form-airlines') : $t('home.flight.form-bus')"
                class="select"
                :class="{'select--error': $v.form.departure_company.$error}"
                :readonly="form.departure_confirmed"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <template #error>
                <div v-if="$v.form.departure_company.$dirty && !$v.form.departure_company.required">{{ $t('medical.validation.req') }}</div>
              </template>
            </app-form-group>
            <app-form-group v-show="form.departure_method === 'flight'" :label="$t('home.flight.form-number')" label-for="departure-flight" required>
              <app-input
                v-model="form.departure_flight_number"
                :placeholder="$t('home.flight.form-number')"
                id="departure-flight"
                :error="$v.form.departure_flight_number.$error"
                :readonly="form.departure_confirmed"
              />
              <template #error>
                <div v-if="$v.form.departure_flight_number.$dirty && !$v.form.departure_flight_number.required">{{ $t('medical.validation.req') }}</div>
                <div v-if="$v.form.departure_flight_number.$dirty && !$v.form.departure_flight_number.flightNumberRegex">{{ $t('validations.only-integer-en') }}</div>
              </template>
            </app-form-group>
            <app-form-group v-show="form.departure_method === 'flight'" :label="$t('home.flight.form-domestic')" class="form-group--center-switch">
              <app-switcher v-model="form.departure_flight_domestic" :readonly="form.departure_confirmed" />
            </app-form-group>
          </div>
          <div class="modal-transport__row">
            <app-form-group :label="$t('home.flight.form-notes')" label-for="departure-notes">
              <app-input
                  v-model="form.departure_notes"
                  :placeholder="$t('home.flight.form-notes')"
                  id="departure-notes"
                  :readonly="form.departure_confirmed"
              />
            </app-form-group>
          </div>
          <div v-if="!form.departure_flight_domestic && form.departure_method === 'flight'" class="modal-transport__row">
            <app-form-group>
              <app-checkbox
                  v-model="form.departure_pcr_required"
                  :label="$t('home.flight.form-pcr')"
                  :readonly="form.departure_confirmed"
              />
            </app-form-group>
          </div>
          <app-form-group v-show="form.departure_method === 'flight'" :label="$t('home.flight.tickets')">
            <app-uploader v-model="form.departure_ticket" :extensions="['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']" />
          </app-form-group>
        </div>
        <app-cells position="end">
          <app-button @click.native="onCheckForm" :disabled="$v.form.$error" ref="submitTransport">
            {{ $t('home.flight.save') }}
          </app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en'
import { getArrivalMethods, getGooglemapsSearch, getArrivalCompanies, patchPersonalArea, postLocation } from '@/http'
import { debounce } from 'lodash'
import axios from '@/axios'
import { required, requiredIf, minValue, helpers } from 'vuelidate/lib/validators'
import AppInfoTextPanel from '@/components/AppInfoTextPanel'
const flightNumberRegex = helpers.regex('flightNumberRegex', /^[a-zA-Z0-9-]*$/i)
const timeRegex = helpers.regex('timeRegex', /^([0-1][0-9]|2[0-3]):([0-5][0-9])?$/i)

export default {
  name: 'Flight',
  components: { AppInfoTextPanel },
  props: {
    transport: Object,
    personal: Object,
  },
  data() {
    return {
      form: {},
      transport_options: [],
      location_options: [],
      company_options: [],
      transport_modal_type: '',
    }
  },
  validations() {
    const form = {
      arrival_method: {},
      arrival_date: {},
      arrival_time: {},
      arrival_location: {},
      arrival_company: {},
      arrival_flight_number: {},
      departure_method: {},
      departure_date: {},
      departure_time: {},
      departure_location: {},
      departure_company: {},
      departure_flight_number: {},
    }
    if (this.transport_modal_type === 'arrival') {
      form.arrival_method = { required }
      form.arrival_date = {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        }
      }
      form.arrival_time = {
        required,
        timeRegex
      }
      form.arrival_location = { required }
      form.arrival_company = {
        required: requiredIf(function() {
          return this.form.arrival_method === 'flight' || this.form.arrival_method === 'bus'
        })
      }
      form.arrival_flight_number = {
        required: requiredIf(function() {
          return this.form.arrival_method === 'flight'
        }),
        flightNumberRegex
      }
    }
    if (this.transport_modal_type === 'departure') {
      form.departure_method = { required }
      form.departure_date = {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        }
      }
      form.departure_time = {
        required,
        timeRegex
      }
      form.departure_location = { required }
      form.departure_company = {
        required: requiredIf(function() {
          return this.form.departure_method === 'flight' || this.form.departure_method === 'bus'
        })
      }
      form.departure_flight_number = {
        required: requiredIf(function() {
          return this.form.departure_method === 'flight'
        }),
        flightNumberRegex
      }
    }

    return { form }
  },
  computed: {
    getCurrentLang() {
      return localStorage.getItem('content_language') === 'ru' ? 'ru' : 'en'
    },
    getArrivalType() {
      return this.transport.arrival.arrival_method ? this.transport.arrival.arrival_method.code : 'unknown'
    },
    getDepartureType() {
      return this.transport.departure.departure_method ? this.transport.departure.departure_method.code : 'unknown'
    },
    getArrivalTypeModal() {
      return this.form.arrival_method ? typeof this.form.arrival_method !== 'string' ? this.form.arrival_method.code : this.form.arrival_method : 'unknown'
    },
    getDepartureTypeModal() {
      return this.form.departure_method ? typeof this.form.departure_method !== 'string' ? this.form.departure_method.code : this.form.departure_method : 'unknown'
    },
    tooltipContent() {
      const arrivalTemplate = this.transport.arrival.arrival_notes ? `<b>${this.$t('home.flight.title_arr')}</b><span>${this.transport.arrival.arrival_notes}</span>` : ''
      const departureTemplate = this.transport.departure.departure_notes ? `<b>${this.$t('home.flight.title_dep')}</b><span>${this.transport.departure.departure_notes}</span>` : ''
      return `${arrivalTemplate}${departureTemplate}`
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
  watch: {
    transport: {
      handler(obj) {
        if (obj) {
          this.form = {
            ...{...obj.arrival, arrival_method: obj.arrival.arrival_method?.code},
            ...{...obj.departure, departure_method: obj.departure.departure_method?.code},
          }
        }
      },
      immediate: true,
    }
  },
  created() {
    getArrivalMethods()
      .then(response => {
        this.transport_options = response.data
      })
    getArrivalCompanies()
      .then(response => {
        this.company_options = response.data
      })
  },
  methods: {
    onArrivalLocationSelected({ name, place_id }) {
      this.$refs.submitTransport.preload = true
      postLocation({ name, place_id }).then(res => {
        this.form.arrival_location = res.data
        this.$refs.submitTransport.preload = false
      })
    },
    onDepartureLocationSelected({ name, place_id }) {
      this.$refs.submitTransport.preload = true
      postLocation({ name, place_id }).then(res => {
        this.form.departure_location = res.data
        this.$refs.submitTransport.preload = false
      })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'error',
          title: this.$t('notify.check'),
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submitTransport.preload = true
      patchPersonalArea(this.$route.params.hash, this.normalizeForm())
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Transport changed',
          })
          this.onHideModal()
          this.$emit('update')
        })
        .catch(() => { this.$refs.submitTransport.preload = false })
    },
    normalizeForm() {
      const form = {...this.form}
      if (this.transport_modal_type === 'arrival') {
        delete form.departure_company
        delete form.departure_confirmed
        delete form.departure_flight_number
        delete form.departure_location
        delete form.departure_method
        delete form.departure_notes
        delete form.departure_time
        delete form.departure_date
        delete form.departure_ticket
        delete form.departure_flight_domestic
        delete form.departure_pcr_required
        if (form.arrival_method !== 'flight') {
          form.arrival_flight_number = null
          form.arrival_ticket = null
          form.arrival_flight_domestic = false
        } else {
          if (form.arrival_ticket && form.arrival_ticket.file) form.arrival_ticket = form.arrival_ticket.id
          else form.arrival_ticket = null
        }
        if (form.arrival_method && typeof form.arrival_method !== 'string') form.arrival_method = form.arrival_method.code
        if (form.arrival_method !== 'flight' && form.arrival_method !== 'bus') form.arrival_company = null
        if (form.arrival_company && typeof form.arrival_company === 'object') form.arrival_company = form.arrival_company.id
        if (form.arrival_location) form.arrival_location = form.arrival_location.id
      }
      if (this.transport_modal_type === 'departure') {
        delete form.arrival_company
        delete form.arrival_confirmed
        delete form.arrival_flight_number
        delete form.arrival_location
        delete form.arrival_method
        delete form.arrival_notes
        delete form.arrival_time
        delete form.arrival_date
        delete form.arrival_ticket
        delete form.arrival_flight_domestic
        if (form.departure_method !== 'flight') {
          form.departure_flight_number = null
          form.departure_ticket = null
          form.departure_flight_domestic = false
          form.departure_pcr_required = false
        } else {
          if (form.departure_ticket && form.departure_ticket.file) form.departure_ticket = form.departure_ticket.id
          else form.departure_ticket = null
          if (form.departure_flight_domestic) form.departure_pcr_required = false
        }
        if (form.departure_method && typeof form.departure_method !== 'string') form.departure_method = form.departure_method.code
        if (form.departure_method !== 'flight' && form.departure_method !== 'bus') form.departure_company = null
        if (form.departure_company && typeof form.departure_company === 'object') form.departure_company = form.departure_company.id
        if (form.departure_location) form.departure_location = form.departure_location.id
      }
      return form
    },
    onChangeTransportMethod(type) {
      this.form[`${type}_company`] = ''
    },
    formatDate(date) {
      if (!date) return ''
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'DD MMMM YYYY', {locale: localStorage.getItem('content_language') === 'ru' ? ruLocale : enLocale})
    },
    onDownloadTickets(ticket, name) {
      axios.get(ticket.file, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const extension = ticket.file_name.split('.').pop()
          link.href = url
          link.setAttribute('download', `${name} - ${this.personal.first_name} ${this.personal.last_name}.${extension}`)
          document.body.appendChild(link)
          link.click()
        })
    },
    onShowModal(type) {
      this.transport_modal_type = type
      this.$v.form.$reset()
      this.$modal.show('transport-modal')
    },
    onHideModal() {
      this.$modal.hide('transport-modal')
    },
    onLocationSearch(search, loading) {
      loading(true)
      this.searchLocation(loading, search, this)
    },
    searchLocation: debounce((loading, search, vm) => {
      if (!search) {
        loading(false)
        vm.location_options = []
      } else {
        getGooglemapsSearch(search).then(response => {
          loading(false)
          vm.location_options = response.data
        })
      }
    }, 350),
  }
}
</script>

<style lang="sass" scoped>
.flight
  flex-grow: 1
  border-radius: 10px
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1)
  background-color: #fff

  +max-w($laptop)
    width: 100%

.flight__holder
  display: flex
  align-items: flex-start
  padding: 16px 16px

  +max-w($mobile_lg)
    width: 610px

  +max-w($mobile_md)
    flex-direction: column
    width: unset
    gap: 10px

  +max-w($mobile_sm)
    padding: 16px 10px 6px

.flight__icons
  display: flex
  align-items: center
  gap: 8px

.flight__footer
  display: flex
  align-items: center
  justify-content: space-between
  flex-wrap: wrap
  gap: 10px
  padding: 8px 16px
  border-top: 1px solid #e5e5e5

  +max-w($mobile_sm)
    padding: 8px 10px

.flight__footer-right
  display: flex
  align-items: center
  gap: 10px

  +max-w($mobile_sm)
    gap: 5px

.flight__part
  display: flex
  align-items: flex-start
  gap: 14px
  width: 50%

  +max-w($mobile_md)
    width: 100%

  &:first-child
    padding-right: 16px
    border-right: 1px dashed #c1c1c1

    +max-w($mobile_md)
      padding-right: 0
      padding-bottom: 10px
      border-right: none
      border-bottom: 1px dashed #c1c1c1

  &:last-child
    padding-left: 16px

    +max-w($mobile_md)
      padding-left: 0

.flight__icon
  flex-shrink: 0
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15)
  line-height: 0
  border-radius: 50%

.flight__title
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
  color: #5f5f5f
  font-size: 13px

.flight__date
  margin-bottom: 8px
  color: #181818
  font-weight: 600

.flight__name
  margin-bottom: 12px
  color: #181818
  font-size: 13px

  span
    font-weight: 500

.flight__text
  color: #5f5f5f
  font-size: 13px

.flight__note
  color: #439ad1
  font-size: 13px
  font-weight: 500

.flight__note--red
  display: block
  margin-bottom: 10px
  color: $color-error

.flight__button
  padding: 4px 10px 4px 29px
  border-radius: 8px
  border: 1px dashed #439ad1
  background-color: #fff
  background-image: url("~@/assets/img/pen-icon.svg")
  background-repeat: no-repeat
  background-position: left 10px top 5px
  color: #439ad1
  font-weight: 500

  &:hover
    border-style: solid

.flight__button--add
  background-image: url("~@/assets/img/add-flight-icon.svg")
  background-position: left 5px top 4px

.flight__additional
  display: flex
  align-items: center
  gap: 16px
  color: $color-theme
  font-size: 13px
  font-weight: 500

  +max-w($mobile_sm)
    gap: 5px

.flight__location
  margin-bottom: 10px
  color: #5f5f5f
  font-size: 13px

.flight__info
  flex-grow: 1

.flight__confirmed
  display: flex
  align-items: center
  gap: 7px
  color: $color-theme
  font-size: 13px
  font-weight: 500

.flight__download
  display: flex
  align-items: center
  gap: 10px
  color: #439ad1
  font-size: 13px
  font-weight: 500
  transition: opacity 0.3s

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus-visible
      opacity: 0.8
</style>
