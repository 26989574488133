import Vue from 'vue'
import AppCells from '@/components/AppCells'
import AppButton from '@/components/AppButton'
import AppFormGroup from '@/components/AppFormGroup'
import AppInput from '@/components/AppInput'
import AppPhone from '@/components/AppPhone'
import AppCheckbox from '@/components/AppCheckbox'
import AppRadio from '@/components/AppRadio'
import AppSwitcher from '@/components/AppSwitcher'
import AppCropperWebCam from '@/components/AppCropperWebCam'
import AppUploader from '@/components/AppUploader'
import AppTableLimit from '@/components/AppTableLimit'
import AppTableRecords from '@/components/AppTableRecords'
import AppFilters from '@/components/AppFilters'
import AppSpinner from '@/components/AppSpinner'
import AppGrid from '@/components/AppGrid'
import AppTextarea from '@/components/AppTextarea'

Vue.component('AppCells', AppCells)
Vue.component('AppButton', AppButton)
Vue.component('AppFormGroup', AppFormGroup)
Vue.component('AppInput', AppInput)
Vue.component('AppPhone', AppPhone)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppRadio', AppRadio)
Vue.component('AppSwitcher', AppSwitcher)
Vue.component('AppCropperWebCam', AppCropperWebCam)
Vue.component('AppUploader', AppUploader)
Vue.component('AppTableLimit', AppTableLimit)
Vue.component('AppTableRecords', AppTableRecords)
Vue.component('AppFilters', AppFilters)
Vue.component('AppSpinner', AppSpinner)
Vue.component('AppGrid', AppGrid)
Vue.component('AppTextarea', AppTextarea)
