import axios from '@/axios'

// ---------------------------------------------------- Clients: Receipts
export function checkClientReceipts(hash) {
  return axios.get(`public/clients/receipts/${hash}/`)
}

// ---------------------------------------------------- Clients: Personal area (Family)
export function checkClientHash(hash) {
  return axios.get(`public/clients/${hash}/`)
}

export function postClientLang(hash, lang) {
  return axios.post(`public/clients/${hash}/`, lang)
}

export function login(hash, last_name) {
  return axios.get(`public/clients/${hash}/personal-area/?last_name=${last_name}`)
}

export function patchPersonalArea(hash, data) {
  return axios.patch(`public/clients/${hash}/personal-area/`, data)
}

export function postMessage(hash, data) {
  return axios.post(`public/clients/${hash}/inquire/`, data)
}

// ---------------------------------------------------- Climbing: Medical clearances (Personal area - Family)
export function getMedicalForm(hash) {
  return axios.get(`public/clients/${hash}/medical-clearance/`)
}

export function patchMedicalForm(hash, data) {
  return axios.patch(`public/clients/${hash}/medical-clearance/`, data)
}

// ---------------------------------------------------- Safari: Snack bar (Personal area - Family)
export function getSnacksCatalog(hash) {
  return axios.get(`public/snack_bar/${hash}/catalog/`)
}

export function patchSnacksOrder(request_hash, payment_method, data) {
  return axios.patch(`public/snack_bar/${request_hash}/orders/?payment_method=${payment_method}`, data)
}

export function createPaySession(hash, payment_method) {
  return axios.get(`public/snack_bar/${hash}/create_session/?payment_method=${payment_method}`)
}

// ---------------------------------------------------- Climbing: Equipment checklist (Personal area - Family)
export function getRentalCatalog(hash, group_id) {
  return axios.get(`public/climbing/groups/${group_id}/clients/${hash}/rental-catalog/`)
}

export function patchEquipmentChecklist(hash, group_id, data) {
  return axios.patch(`public/climbing/groups/${group_id}/clients/${hash}/rental-catalog/`, data)
}

export function getEquipmentChecklist(slug) {
  return axios.get(`public/climbing/equipment-checklist/${slug}/`)
}

// ---------------------------------------------------- Clients: Invoices
export function getPublicClientInvoice(hash) {
  return axios.get(`public/finance/invoices/${hash}/`)
}

export function putPublicClientInvoice(hash, data) {
  return axios.put(`public/finance/invoices/${hash}/`, data)
}

// ---------------------------------------------------- Dicts
export function getGenders() {
  return axios.get('dicts/genders/')
}

export function getCountries() {
  return axios.get('dicts/countries/')
}

export function getLangueage() {
  return axios.get('dicts/languages/')
}

export function getCovidVaccines() {
  return axios.get('dicts/covid-vaccines/?active=true')
}

export function getFoodPreferences() {
  return axios.get('dicts/food-preferences/')
}

export function getArrivalMethods() {
  return axios.get('dicts/arrival-departure-methods/')
}

export function getArrivalCompanies(type = '', search = '') {
  return axios.get(`dicts/arrival-departure-companies/?type=${type}&search=${search}`)
}

export function getGooglemapsSearch(search) {
  return axios.get(`googlemaps/search/?Search=${search}`)
}

export function getSmokingFrequencies() {
  return axios.get('medical-clearances/smoking-frequencies/?active=true')
}

export function getDiabetesTypes() {
  return axios.get('medical-clearances/diabetes/?active=true')
}

export function postLocation(data) {
  return axios.post('dicts/locations/', data)
}

// ---------------------------------------------------- Files
export function postFile(data) {
  return axios.post('files/', data)
}
