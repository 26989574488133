import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import './assets'
import './global'
import './my-components'

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    dsn: 'https://e13c83dfc14345c5a1f26549f5b09e4d@o1276910.ingest.sentry.io/6474150',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['dev-family.altezza.travel', 'family.altezza.travel', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
