<template>
  <button
      class="btn"
      :disabled="disabled"
      :type="type"
      :class="[ themes[theme], sizes[size], { 'btn--beauty': disabled }, { 'btn--preload': preload } ]"
  >
    <slot v-if="!preload" />
    <img v-if="preload" src="@/assets/img/preloader.svg" alt="" class="btn__preloader" />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  data() {
    return {
      themes: {
        default: 'btn--default',
        dark: 'btn--dark',
        error: 'btn--error',
        tooltip: 'btn--tooltip',
        warn: 'btn--warn',
      },
      sizes: {
        normal: '',
        small: 'btn--small'
      },
      preload: false,
    }
  },
  props: {
    theme: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'button'
    },
  },
}
</script>

<style lang="sass">

.btn
  position: relative
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  min-width: 130px
  min-height: 38px
  padding: 12px 14px 11px
  border-radius: 8px
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15)
  color: #fff
  font-size: 15px
  font-weight: 500
  line-height: 1
  text-align: center
  text-decoration: none
  transition: background-color 0.3s
  cursor: pointer
  user-select: none

.btn--preload
  pointer-events: none

.btn--default
  background-color: $color-theme

  &:hover
    background-color: #72a915

.btn--dark
  background-color: #939393

  &:hover
    background-color: #434343

.btn--error
  background-color: #dd4e4e

  &:hover
    background-color: #d23535

.btn--stretch
  width: 100%

.btn--beauty
  background-color: #c4c4c4
  pointer-events: none

.btn--small
  min-width: auto
  min-height: 100%
  padding: 9px 13px 8px
  font-size: 13px
  font-weight: 500

.btn--tooltip
  min-width: auto
  min-height: auto
  width: 15px
  height: 15px
  padding: 0
  border-radius: 0
  background-image: url("~@/assets/img/tooltip-icon.svg")
  background-repeat: no-repeat
  background-position: right center
  background-size: cover
  box-shadow: none

.btn--tooltip-big
  width: 22px
  height: 22px

.btn--warn
  background-color: #f0c427

  &:hover
    background-color: #ebc027

.btn__preloader
  position: absolute
  top: 50%
  left: 50%
  width: 20px
  height: 20px
  transform: translate(-50%, -50%)
</style>
