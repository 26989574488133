<template>
  <div class="spinner"></div>
</template>

<script>
export default {
  name: 'AppSpinner'
}
</script>

<style lang="sass" scoped>
.spinner
  position: fixed
  top: calc(50% - 100px)
  left: 50%
  width: 56px
  height: 56px
  border-radius: 50%
  background: radial-gradient(farthest-side, #77b017 94%, #0000) top/6.4px 6.4px no-repeat, conic-gradient(#0000 30%, #77b017)
  mask: radial-gradient(farthest-side, #0000 calc(100% - 6.4px), #000 0)
  animation: spinner-rotate 0.8s infinite linear

@keyframes spinner-rotate
  100%
    transform: rotate(1turn)

</style>
