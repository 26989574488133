<template>
  <div class="managers" ref="managers">
    <div class="managers__title">
      <span>{{ $t('home.managers.single') }}</span>
      <app-button @click.native="onShowModal" size="small">
        <img src="@/assets/img/mail.svg" alt="Mail">
        {{ $t('home.mail-modal.btn') }}
      </app-button>
    </div>
    <div class="managers__body">
      <div v-if="created_by" class="managers__item">
        <div class="managers__pic">
          <img v-if="created_by.crop.crop_url" :src="created_by.crop.crop_url" alt="">
        </div>
        <div class="managers__info">
          <div class="managers__name">{{ created_by.first_name }}</div>
          <a v-if="created_by.email" :href="`mailto:${created_by.email}`" class="managers__email">{{ created_by.email }}</a>
          <div v-if="created_by.phone" class="managers__row">
            <a :href="`tel:${created_by.phone}`" class="managers__phone">{{ created_by.phone }}</a>
            <div class="managers__messengers">
              <a v-if="created_by.phone_telegram" :href="`https://t.me/${created_by.phone}`" target="_blank">
                <img src="@/assets/img/tg.svg" alt="">
              </a>
              <a v-if="created_by.phone_whatsapp" :href="`https://wa.me/${created_by.phone}`" target="_blank">
                <img src="@/assets/img/wa.svg" alt="">
              </a>
            </div>
          </div>
          <div class="managers__row">
            <a href="tel:+2551234567890" class="managers__phone">+255699373358</a>
            <div class="managers__messengers">
              <a href="https://t.me/+255699373358" target="_blank">
                <img src="@/assets/img/tg.svg" alt="">
              </a>
              <a href="https://wa.me/+255699373358" target="_blank">
                <img src="@/assets/img/wa.svg" alt="">
              </a>
            </div>
            <span>(Hotline)</span>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="mail-modal"
      :width="400"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--mail">
        <div class="modal__top">
          <span>{{ $t('home.mail-modal.title') }}</span>
        </div>
        <form @submit.prevent="onSendForm">
          <app-form-group>
            <app-textarea
              v-model="message"
              :placeholder="$t('home.mail-modal.text')"
            />
          </app-form-group>
          <app-cells position="center" :indent="false">
            <app-button type="submit" :disabled="!message" ref="submit">{{ $t('home.mail-modal.send') }}</app-button>
          </app-cells>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { postMessage } from '@/http'

export default {
  name: 'Managers',
  props: {
    created_by: {
      type: Object
    },
    managers: {
      type: Array
    },
    hash: {
      type: String
    }
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    onShowModal() {
      this.message = ''
      this.$modal.show('mail-modal')
    },
    onSendForm() {
      this.$refs.submit.preload = true
      postMessage(this.hash, {message: this.message})
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('home.mail-modal.notify'),
          })
          this.$modal.hide('mail-modal')
        })
        .catch(() => { this.$refs.submit.preload = false })
    }
  }
}
</script>
<style lang="sass" scoped>
.managers__title
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 16px
  color: #181818
  font-size: 16px
  font-weight: 600

.managers__body
  display: flex
  flex-wrap: wrap
  gap: 30px

  +max-w($mobile_xl)
    flex-direction: column
    gap: 20px

.managers__item
  display: flex
  align-items: center
  gap: 10px 22px
  width: calc(50% - 15px)
  padding: 16px 16px 20px
  border-radius: 10px
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1)
  background-color: #fff

  +max-w($mobile_xl)
    width: 100%

.managers__pic
  flex-shrink: 0
  width: 92px
  height: 92px
  border-radius: 50%
  border: 3px solid #fff
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15)
  background-color: #d9d9d9
  overflow: hidden

  img
    width: 100%
    height: 100%
    object-fit: cover

.managers__name
  margin-bottom: 3px
  color: #181818
  font-weight: 600

.managers__email
  display: inline-block
  margin-bottom: 8px
  color: $color-theme
  font-size: 13px
  font-weight: 500

.managers__row
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 6px

  &:last-child
    margin-bottom: 0

  span
    color: #8a8a8a
    font-size: 13px

.managers__phone
  color: #5f5f5f

.managers__messengers
  display: flex
  align-items: center
  gap: 7px

  a
    line-height: 0

</style>
