<template>
  <input
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="type"
      class="input"
      :class="{ 'input--error': error }"
  />
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: [String, Number, Date],
    type: {
      default: 'text',
      validator: value => {
        return ['text', 'email', 'password', 'url', 'number', 'datetime-local', 'search', 'time'].includes(value)
      }
    },
    error: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="sass">
.input
  width: 100%
  height: 38px
  padding: 10px 12px 11px
  border: 1px solid $color-border
  border-radius: 6px
  color: $color-text-base
  background-color: #fff
  font-family: $font-family-base
  font-size: 13px
  font-weight: 400
  line-height: 15.5px
  transition: border-color 0.3s, color 0.3s, background-color 0.3s

  &::placeholder
    color: #818181

  &:focus
    border-color: #aaa

  &[readonly]
    pointer-events: none
    border-color: #ff9416

  &[type="search"]
    padding-right: 30px
    background-image: url("~@/assets/img/search-input.svg")
    background-repeat: no-repeat
    background-position: right 10px center
    background-size: 14px

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  &[type=number]
    -moz-appearance: textfield

.input--error
  border-color: $color-error

.input--300
  max-width: 300px

</style>
