<template>
  <div class="tips">
    <app-header class="header--tips" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  name: 'TipsLayout',
  components: { AppHeader },
  mounted() {
    localStorage.removeItem('tour_operator')
  }
}
</script>

<style lang="sass" scoped>
.tips
  width: 100%

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
