<template>
  <div class="auth">
    <div class="auth__logo">
      <img v-if="branding === 'false'" src="@/assets/img/logo-main-black.svg" alt="">
      <span v-if="branding === 'true'">{{ tourOperator }}</span>
    </div>
    <div class="auth__text">{{ $t('login.text') }}</div>
    <div class="auth__body">
      <div v-if="branding === 'false'" class="auth__title">{{ $t('login.title') }}</div>
      <div v-if="branding === 'true'" class="auth__title">{{ $t('login.title_branding') }}</div>
      <form @submit.prevent="sendForm">
        <app-form-group :label="$t('login.label')" label-for="last_name">
          <app-input
              v-model="last_name"
              id="last_name"
              :placeholder="$t('login.placeholder')"
              @input="onChangeRUToUS"
              ref="login_input"
          />
        </app-form-group>
        <app-cells position="center" :indent="false">
          <app-button ref="submit" type="submit" :disabled="!last_name">{{ $t('login.button') }}</app-button>
        </app-cells>
      </form>
    </div>
  </div>
</template>

<script>
import { login } from '@/http'

export default {
  name: 'Login',
  data() {
    return {
      last_name: null,
    }
  },
  computed: {
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
    tourOperator() {
      return JSON.parse(localStorage.getItem('tour_operator'))
    }
  },
  methods: {
    onChangeRUToUS(value) {
      let answer = ''
      const converter = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'e',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'y',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'h',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'щ': 'sch',
        'ь': '',
        'ы': 'y',
        'ъ': '',
        'э': 'e',
        'ю': 'yu',
        'я': 'ya',
        'А': 'A',
        'Б': 'B',
        'В': 'V',
        'Г': 'G',
        'Д': 'D',
        'Е': 'E',
        'Ё': 'E',
        'Ж': 'Zh',
        'З': 'Z',
        'И': 'I',
        'Й': 'Y',
        'К': 'K',
        'Л': 'L',
        'М': 'M',
        'Н': 'N',
        'О': 'O',
        'П': 'P',
        'Р': 'R',
        'С': 'S',
        'Т': 'T',
        'У': 'U',
        'Ф': 'F',
        'Х': 'H',
        'Ц': 'C',
        'Ч': 'Ch',
        'Ш': 'Sh',
        'Щ': 'Sch',
        'Ь': '',
        'Ы': 'Y',
        'Ъ': '',
        'Э': 'E',
        'Ю': 'Yu',
        'Я': 'Ya'
      }

      for (let i = 0; i < value.length; ++i) {
        if (converter[value[i]] === undefined) {
          answer += value[i]
        } else {
          answer += converter[value[i]]
        }
      }
      this.last_name = answer
    },
    sendForm() {
      this.$refs.login_input.$el.blur()
      this.$refs.submit.preload = true
      login(this.$route.params.hash, this.last_name)
        .then(() => {
          localStorage.setItem('last_name', this.last_name)
          this.$router.push({ name: 'home', params: { hash: this.$route.params.hash } })
        })
        .catch(() => { this.$refs.submit.preload = false })
    }
  }
}
</script>

<style lang="sass" scoped>
.auth__logo
  margin-bottom: 30px
  text-align: center
  line-height: 0

  span
    font-size: 21px
    font-weight: 600
    line-height: 1

.auth__title
  margin-bottom: 35px
  color: #1a1a1a
  font-size: 16px
  font-weight: 600
  text-align: center

.auth__text
  max-width: 400px
  margin: 0 auto 30px
  text-align: center

.auth__body
  width: 400px
  padding: 40px 50px 47px
  background-color: #fff
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1)
  border-radius: 14px
</style>
