<template>
  <div class="activities">
    <div v-if="!activities.length" class="activities__empty">
      <div class="activities__empty-icon">
        <img src="@/assets/img/communication.svg" alt="">
      </div>
      <div class="activities__empty-body">
        <div class="activities__empty-holder">
          <div class="activities__empty-title">{{ $t('home.activities.empty') }}</div>
          <div class="activities__empty-text">
            {{ $t('home.activities.detail') }}
            <a
              @click.prevent="onScrollToManagers"
              type="button"
              class="base-link"
            >
              {{ $t('home.activities.manager') }}
            </a>
          </div>
        </div>
        <app-button @click.native="onShowModal" size="small">
          <img src="@/assets/img/mail.svg" alt="Mail">
          {{ $t('home.mail-modal.btn') }}
        </app-button>
      </div>
    </div>
    <div v-else class="activities__list">
      <div v-for="item in activities" :key="item.id" class="activities__item">
        <div class="activities__top">
          <div v-if="item.activity === 'climbing'" class="activities__title">{{ $t('home.activities.climbing.title') }} {{ item.route.mountain.name }}</div>
          <div v-else class="activities__title">{{ $t('home.activities.safari.title') }}</div>
          <div class="activities__info">
            <span v-if="item.activity === 'climbing'">{{ $t('home.activities.climbing.route') }}: <b>{{ item.route.name }}</b></span>
            <span v-if="item.activity === 'climbing'">{{ $t('home.activities.climbing.duration') }}: <b>{{ item.route.duration }}</b></span>
            <span v-else>{{ $t('home.activities.safari.duration') }}: <b>{{ item.duration }}</b></span>
            <span>{{ $t('home.activities.climbing.start') }}: <b>{{ dateFormatting(item.tour_start) }}</b></span>
            <span>{{ $t('home.activities.climbing.finish') }}: <b>{{ dateFormatting(item.tour_end) }}</b></span>
          </div>
        </div>
        <template v-if="item.snack_bar">
          <p v-if="item.orders_available && !item.snack_bar.completed" class="activities__info-text">
            {{ $t('home.activities.safari.info-text') }}
          </p>
          <p
            v-if="item.orders_available && item.snack_bar.completed && item.snack_bar.outstanding_amount <= 0"
            class="activities__info-text"
          >
            {{ $t('home.activities.safari.info-text1') }}
          </p>
          <p
            v-if="!item.orders_available && item.snack_bar.completed && item.snack_bar.outstanding_amount <= 0"
            class="activities__info-text"
          >
            {{ $t('home.activities.safari.info-text2') }}
          </p>
          <p
            v-if="!item.orders_available && item.snack_bar.completed && item.snack_bar.outstanding_amount > 0"
            class="activities__info-text"
          >
            {{ $t('home.activities.safari.info-text3') }}
          </p>
        </template>
        <div v-if="item.activity === 'climbing'" class="activities__row">
          <div class="activities__name">
            <img src="@/assets/img/checklist-icon.svg" alt="">
            <span>{{ $t('home.activities.climbing.equipment') }}</span>
            <img v-if="item.checklist.completed" src="@/assets/img/approved-icon.svg" alt="">
            <img v-else src="@/assets/img/clock-snacks.svg" alt="Clock">
          </div>
          <div class="activities__right">
<!--            <div v-if="item.checklist.completed" class="activities__edited">{{ $t('home.activities.climbing.edited') }}: 04 Jun 2021</div>-->
            <div class="activities__controls">
              <app-button size="small" @click.native="onClimbingRentals(item.id)">
                <template v-if="!item.checklist.completed">
                  <img src="@/assets/img/submit-activities.svg" alt="Submit">
                  {{ $t('home.activities.climbing.submit') }}
                </template>
                <template v-else>
                  <img src="@/assets/img/pen-white-icon.svg" alt="">
                  {{ $t('home.activities.safari.edit') }}
                </template>
              </app-button>
            </div>
          </div>
        </div>
        <div v-else class="activities__row">
          <div class="activities__name">
            <img src="@/assets/img/snacks-icon.svg" alt="">
            <span>{{ $t('home.activities.safari.snack') }}</span>
            <img v-if="item.snack_bar && item.snack_bar.completed" src="@/assets/img/approved-icon.svg" alt="">
            <img v-else src="@/assets/img/clock-snacks.svg" alt="Clock">
          </div>
          <div class="activities__right">
            <template v-if="item.snack_bar">
              <div class="activities__edited">
                <p v-if="item.snack_bar.completed"><span>{{ $t('home.activities.safari.total') }}:</span> <span>{{ item.snack_bar.order_amount }}$</span></p>
                <p v-if="item.snack_bar.completed && item.snack_bar.outstanding_amount">
                  <span>{{ $t('home.activities.safari.amount-pay') }}:</span> <span>{{ item.snack_bar.outstanding_amount }}$</span>
                </p>
              </div>
              <div class="activities__controls">
                <app-button
                  @click.native="onSnacksEditClick(item.hash)"
                  size="small"
                >
                  <template v-if="!item.snack_bar.completed">
                    <img src="@/assets/img/submit-activities.svg" alt="Submit">
                    {{ $t('home.activities.safari.submit') }}
                  </template>
                  <template v-else>
                    <div class="activities__center">
                      <template v-if="item.orders_available">
                        <img src="@/assets/img/pen-white-icon.svg" alt="Edit">
                        {{ $t('home.activities.safari.edit') }}
                      </template>
                      <template v-else>
                        <img src="@/assets/img/view-icon.svg" alt="View">
                        {{ $t('home.activities.safari.view') }}
                      </template>
                    </div>
                  </template>
                </app-button>
                <app-button
                  v-if="item.snack_bar.completed && item.snack_bar.outstanding_amount"
                  @click.native="onShowModalPaySession(item.hash)"
                  size="small"
                >
                  <div class="activities__center">
                    <img src="@/assets/img/pay-icon.svg" alt="">
                    {{ $t('home.activities.safari.pay') }}
                  </div>
                </app-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="payment-modal"
      :width="500"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--cart">
        <div class="modal__payment modal__payment--no-indent">
          <span class="modal__title">{{ $t('snacks.method') }}</span>
          <app-cells :indent="false">
            <app-radio
              v-model="payment_method"
              value="stripe"
              name="payment"
              class="radio--minw"
            >
              <template #label>{{ $t('snacks.payStripe') }}</template>
              <template #label-additional>{{ $t('snacks.payKassaNotRF') }}</template>
            </app-radio>
            <app-radio
              v-if="contentLanguage === 'ru'"
              v-model="payment_method"
              value="kassa"
              name="payment"
              class="radio--minw"
            >
              <template #label>{{ $t('snacks.payKassa') }}</template>
              <template #label-additional>{{ $t('snacks.payKassaRF') }}</template>
            </app-radio>
          </app-cells>
          <app-cells position="center" :indent="false">
            <app-button theme="dark" size="normal" @click.native="$modal.hide('payment-modal')">{{ $t('snacks.close') }}</app-button>
            <app-button size="normal" @click.native="onCreatePaySession(null)" ref="createSession">{{ $t('home.activities.safari.pay') }}</app-button>
          </app-cells>
        </div>
      </div>
    </modal>
    <modal
      name="mail-actv-modal"
      :width="400"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--mail">
        <div class="modal__top">
          <span>{{ $t('home.mail-modal.title') }}</span>
        </div>
        <form @submit.prevent="onSendForm">
          <app-form-group>
            <app-textarea
              v-model="message"
              :placeholder="$t('home.mail-modal.text')"
            />
          </app-form-group>
          <app-cells position="center" :indent="false">
            <app-button type="submit" :disabled="!message" ref="submit">{{ $t('home.mail-modal.send') }}</app-button>
          </app-cells>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import { createPaySession, postMessage } from '@/http'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en'

export default {
  name: 'Activities',
  props: {
    activities: {
      type: Array,
      required: true
    },
    hash: {
      type: String
    }
  },
  data() {
    return {
      payment_method: 'stripe',
      snacks_hash: '',
      message: '',
    }
  },
  computed: {
    contentLanguage() {
      return localStorage.getItem('content_language')
    }
  },
  methods: {
    onShowModalPaySession(hash) {
      this.snacks_hash = hash
      if (this.contentLanguage === 'ru') {
        this.$modal.show('payment-modal')
      } else {
        this.onCreatePaySession(hash)
      }
    },
    onCreatePaySession(hash) {
      if (!hash) {
        this.$refs.createSession.preload = true
      }
      createPaySession(this.snacks_hash, this.payment_method)
        .then(res => { window.open(res.data.url, '_self') })
        .catch(() => { if (!hash) this.$refs.createSession.preload = false })
    },
    dateFormatting(date) {
      if (!date) return ''
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'DD MMMM YYYY', {locale: localStorage.getItem('content_language') === 'ru' ? ruLocale : enLocale})
    },
    onSnacksEditClick(hash) {
      this.$router.push({ name: 'snacks', params: { hashRequest: hash } })
    },
    onClimbingRentals(groupId) {
      this.$router.push({ name: 'rentals', params: { groupId } })
    },
    onScrollToManagers() {
      const element = this.$parent.$children[3].$refs.managers
      element.scrollIntoView({ behavior: 'smooth' })
    },
    onShowModal() {
      this.message = ''
      this.$modal.show('mail-actv-modal')
    },
    onSendForm() {
      this.$refs.submit.preload = true
      postMessage(this.hash, {message: this.message})
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('home.mail-modal.notify'),
          })
          this.$modal.hide('mail-actv-modal')
        })
        .catch(() => { this.$refs.submit.preload = false })
    }
  }
}
</script>

<style lang="sass" scoped>
.activities__photo
  display: inline-block
  width: 24px
  height: 24px
  border-radius: 50%
  border: 2px solid #fff
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15)
  overflow: hidden

  img
    width: 100%
    height: 100%
    object-fit: cover

.activities__empty
  display: flex
  align-items: center
  gap: 10px 20px
  margin-bottom: 20px
  padding: 24px
  border: 1px solid #eaeaea
  border-radius: 10px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08)
  background-color: #fff

  +max-w($mobile_md)
    align-items: flex-start

.activities__empty-icon
  flex-shrink: 0
  line-height: 0

.activities__empty-body
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: space-between

  +max-w($mobile_md)
    gap: 10px
    flex-direction: column
    align-items: flex-start

.activities__empty-title
  margin-bottom: 6px
  color: #181818
  font-weight: 500
  text-transform: uppercase

.activities__empty-text
  color: #a0a0a0

.activities__list
  margin-bottom: 40px

  &:last-child
    margin-bottom: 0

.activities__item
  margin-bottom: 40px

  &:last-child
    margin-bottom: 0

.activities__top
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px

  +max-w($mobile_lg)
    flex-direction: column
    align-items: flex-start
    gap: 10px

.activities__title
  color: #181818
  font-size: 15px
  font-weight: 600

.activities__info
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 10px 30px

  span
    color: #5e5e5e
    font-size: 13px
    font-weight: 500

    b
      color: #181818
      font-weight: 500

.activities__row
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  margin-bottom: 10px
  padding: 9px 12px
  border: 1px solid #eaeaea
  border-radius: 10px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08)
  background-color: #fff

  +max-w($mobile_md)
    flex-direction: column
    align-items: flex-start

  &:last-child
    margin-bottom: 0

.activities__name
  flex-shrink: 0
  display: flex
  align-items: center
  gap: 10px
  line-height: 0

  span
    color: #181818
    font-weight: 500

.activities__right
  display: flex
  align-items: center
  gap: 10px 30px

  +max-w($mobile_lg)
    flex-direction: column
    align-items: flex-end

  +max-w($mobile_md)
    align-items: flex-start

.activities__edited
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 10px 25px

  p
    color: #959595
    font-size: 13px

    span:last-child
      color: #181818

.activities__center
  display: flex
  align-items: center
  gap: 6px

.activities__control-list
  display: flex
  align-items: center
  gap: 10px

  button
    border-radius: 9px
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15)
    line-height: 0
    transition: transform 0.3s

    &:hover
      transform: translateY(-1px)

.activities__not-found
  color: $color-error

.activities__controls
  display: flex
  align-items: center
  gap: 10px

.activities__info-text
  margin-bottom: 15px
  color: #5e5e5e
  font-size: 13px
</style>
