import Vue from 'vue'
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Accept-Language': localStorage.getItem('content_language') || 'us'
  }
})

axiosInstance.interceptors.response.use(undefined, function (err) {
  return new Promise(function () {
    if (err.response.status === 500) {
      Vue.notify({
        type: 'error',
        title: 'A critical error has occurred, contact your administrator'
      })
      throw err
    }
    for (const key in err.response.data) {
      if (typeof err.response.data[key] === 'string') {
        Vue.notify({
          type: 'error',
          title: err.response.data[key],
        })
      } else {
        Vue.notify({
          type: 'error',
          title: err.response.data[key][0],
        })
      }
    }

    throw err
  })
})

export default axiosInstance
