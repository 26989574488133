<template>
  <div class="default-layout">
    <app-header />
    <main class="main">
      <div class="container">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  components: { AppHeader },
}
</script>

<style lang="sass">
.default-layout
  flex-grow: 1
  display: flex
  flex-direction: column

.main
  padding: 92px 0 35px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
