<template>
  <div class="filters">
    <label
        v-for="filter of filters"
        :key="filter.value"
        class="filters__label">
      <input
        @change="$emit('change', $event.target.value)"
        type="radio"
        :name=name
        :value="filter.value"
        :checked="filter.value === value"
      >
      <span class="filters__item">{{ filter.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppFilters',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    name: String,
    value: {
      type: String,
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  }
}
</script>

<style scoped lang="sass">
.filters
  display: flex
  flex-shrink: 0
  overflow: hidden
  border: 1px solid #dbdbdb
  border-radius: 6px

  input[type="radio"]
    +visually-hidden

    &:checked + .filters__item
      background-color: $color-theme
      color: #fff
      pointer-events: none

.filters__label
  border-right: 1px solid #dbdbdb

  &:last-child
    border: none

.filters__item
  display: block
  padding: 11px 22px 10px
  background-color: #fff
  font-size: 13px
  cursor: pointer
  transition: opacity 0.3s

  &:hover
    opacity: 0.7

</style>
